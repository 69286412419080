import { sample } from "effector"

import { AppGate } from "../app"
import { $isReady, $token, restoreFx, signIn, signInFx, signOut, signOutFx } from "./auth-units"

sample({
	clock: AppGate.open,
	target: restoreFx,
})

sample({
	clock: restoreFx.finally,
	fn: () => true,
	target: $isReady,
})

sample({
	clock: signIn,
	target: signInFx,
})

sample({
	clock: [signInFx.doneData, restoreFx.doneData],
	fn: ({ token }) => token,
	target: $token,
})

sample({
	clock: signOut,
	target: signOutFx,
})

sample({
	clock: signOutFx.done,
	fn: () => null,
	target: $token,
})
