import { registerPlugin } from "@capacitor/core"

type Params = {
	email: string
	subject: string
	fileName: string
	base64: string
}

type SharePlugin = {
	sendEmail(params: Params): Promise<void>
}

export const Share = registerPlugin<SharePlugin>("Share")
