import { useIntl } from "react-intl"
import { useUnit } from "effector-react"

import {
	$isLogoShown,
	$isOverlayShown,
	isLogoShownChanged,
	isOverlayShownChanged,
} from "../store/editor"
import { AlignmentControls } from "./alignment-controls"
import classes from "./controls-section.module.css"
import { Presets } from "./presets"
import { Switch } from "./switch"

export function ControlsSection() {
	const intl = useIntl()

	const { logo, overlay } = useUnit({
		logo: $isLogoShown,
		overlay: $isOverlayShown,
	})

	return (
		<div className={classes.root}>
			<AlignmentControls />

			<Switch checked={logo} onChange={isLogoShownChanged}>
				{intl.formatMessage({
					id: "editor-native-qrcode-logo-toggle",
					defaultMessage: "Quantori logo",
				})}
			</Switch>

			<Switch checked={overlay} onChange={isOverlayShownChanged}>
				{intl.formatMessage({
					id: "editor-native-elements-toggle",
					defaultMessage: "Native iOS elements",
				})}
			</Switch>

			<Presets />
		</div>
	)
}
