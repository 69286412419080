const UpdateIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M21 11.9958V18.9958C21 19.5263 20.7891 20.035 20.4141 20.41C20.039 20.7851 19.5304 20.9958 19 20.9958H5C4.46957 20.9958 3.96077 20.7851 3.58569 20.41C3.21062 20.035 3 19.5263 3 18.9958V4.99585C3 4.46542 3.21062 3.95674 3.58569 3.58167C3.96077 3.20659 4.46957 2.99585 5 2.99585H12" stroke="black" strokeWidth="2" strokeMiterlimit="10"/>
        <path d="M10 10.7258V13.9958H13.2798L21.71 5.5658C21.8962 5.37843 22.0007 5.12503 22.0007 4.86084C22.0007 4.59665 21.8962 4.34325 21.71 4.15588L19.8499 2.2959C19.7569 2.20217 19.6463 2.12767 19.5244 2.0769C19.4026 2.02614 19.2719 2 19.1399 2C19.0079 2 18.8772 2.02614 18.7554 2.0769C18.6335 2.12767 18.5229 2.20217 18.4299 2.2959L10 10.7258Z" stroke="black" strokeWidth="2" strokeLinejoin="round"/>
    </svg>
  )
}

export default UpdateIcon
