import { FallbackProps } from "react-error-boundary"
import { useIntl } from "react-intl"
import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle } from "@ionic/react"

import classes from "./error-boundary-fallback.module.css"

export function ErrorBoundaryFallback({ error, resetErrorBoundary }: FallbackProps) {
	const intl = useIntl()

	return (
		<div className={classes.root}>
			<IonCard className={classes.card}>
				<IonCardHeader>
					<IonCardTitle>Something went wrong.</IonCardTitle>
				</IonCardHeader>

				<IonCardContent className={classes.errorStack}>{error?.stack}</IonCardContent>

				<IonButton onClick={resetErrorBoundary} fill="clear">
					{intl.formatMessage({
						id: "error-boundary-fallback-reload-button",
						defaultMessage: "Reload",
					})}
				</IonButton>
			</IonCard>
		</div>
	)
}
