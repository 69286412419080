import React from "react"
import { createRoot } from "react-dom/client"

import { App } from "./app"
import { assert } from "./lib/assert"

const container = document.getElementById("root")
assert(container, "Could not find root element")

const root = createRoot(container)

root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
)
