import { Network } from "@capacitor/network"

import { appDomain } from "../app"

const domain = appDomain.createDomain("network")

export const isOnlineChanged = domain.createEvent<boolean>()

export const $isConnected = domain.createStore(false)

export const listenNetworkStatusFx = domain.createEffect(async () => {
	Network.addListener("networkStatusChange", (event) => isOnlineChanged(event.connected))

	const { connected } = await Network.getStatus()
	isOnlineChanged(connected)
})

export const stopListenNetworkStatusFx = domain.createEffect(async () => {
	Network.removeAllListeners()
})
