export function ContactBookIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={22} height={22} fill="none">
			<path
				fill="var(--ion-color-primary)"
				fillRule="evenodd"
				d="M10.438 0a5 5 0 0 0-5 5v2a5 5 0 0 0 10 0V5a5 5 0 0 0-5-5ZM8.317 2.879A3 3 0 0 1 13.438 5v2a3 3 0 0 1-6 0V5a3 3 0 0 1 .879-2.121Zm-5.94 18.467C3.786 17.522 6.951 15 10.437 15v-2C5.925 13 2.13 16.238.5 20.654l1.876.692ZM16.437 19h-5v-2h5v-3l5 4-5 4v-3Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}
