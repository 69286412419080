import QRCode from "easyqrcodejs"

import { config } from "../config"
import { createImage } from "./create-image"

export async function generateQrCodeImage(params: {
	text: string
	size: number
	logo: string | null
}): Promise<HTMLImageElement> {
	const { text, size, logo } = params

	const data = await new Promise<string>((resolve) => {
		const options = {
			width: size,
			height: size,
			quietZone: config.qrCode.quietZone,

			text,
			onRenderingEnd: (_: unknown, data: string) => resolve(data),
		}

		if (logo) {
			Object.assign(options, {
				logo,
				logoWidth: size / 4,
				logoHeight: size / 4,
				logoTransparent: true,
			})
		}

		new QRCode(document.createElement("div"), options)
	})

	return await createImage(data)
}
