import { useState } from "react"
import { useIntl } from "react-intl"
import { IonButton, IonModal } from "@ionic/react"
import clsx from "clsx"

import { signOut } from "../store/auth"
import classes from "./sign-out-button.module.css"
import { SignOutIcon } from "./sign-out-icon"

export const SignOutButton = () => {
	const intl = useIntl()

	const [showAlert, setShowAlert] = useState(false)
	const handleAlertCancel = () => {
		setShowAlert(false)
	}

	const handleAlertOk = () => {
		setShowAlert(false)
		signOut()
	}

	return (
		<div>
			<IonButton onClick={() => setShowAlert(true)}>
				<div className={classes.signOut}>
					<SignOutIcon />
					<p className={classes.signOutTitle}>
						{intl.formatMessage({
							id: "sign-out-button",
							defaultMessage: "Sign out",
						})}
					</p>
				</div>
			</IonButton>
			<IonModal
				isOpen={showAlert}
				className={classes.signOutModal}
				onDidDismiss={handleAlertCancel}
			>
				<div className={classes.modalWrapper}>
					<h3 className={classes.modalTitle}>
						{intl.formatMessage({
							id: "do-you-want-sign-out",
							defaultMessage: "Are you sure you want to sign out?",
						})}
					</h3>
					<div className={classes.modalButtons}>
						<div className={clsx(classes.modalButtonWrapper, classes.modalButtonFirst)}>
							<IonButton
								className={classes.modalButton}
								fill="clear"
								onClick={handleAlertCancel}
							>
								{intl.formatMessage({
									id: "cancel-sign-out",
									defaultMessage: "Cancel",
								})}
							</IonButton>
						</div>
						<div className={classes.modalButtonWrapper}>
							<IonButton
								className={classes.modalButton}
								fill="clear"
								onClick={handleAlertOk}
							>
								{intl.formatMessage({
									id: "ok-sign-out",
									defaultMessage: "Ok",
								})}
							</IonButton>
						</div>
					</div>
				</div>
			</IonModal>
		</div>
	)
}
