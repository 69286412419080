import { useState } from "react"
import { useIntl } from "react-intl"
import { IonButton, IonModal } from "@ionic/react"
import clsx from "clsx"

import classes from "./delete-vcard.module.css"
import { GarbageIcon } from "./garbage-icon"
type Props = {
	handleDeleteVCards?: () => void
	handleDeleteEvents?: () => void
	disabled: boolean
}
export function DeleteVcard({ handleDeleteVCards, disabled, handleDeleteEvents }: Props) {
	const intl = useIntl()
	const [showAlert, setShowAlert] = useState(false)
	const handleAlertCancel = () => {
		setShowAlert(false)
	}
	const handleAlertOk = () => {
		setShowAlert(false)
		handleDeleteVCards?.()
		handleDeleteEvents?.()
	}
	return (
		<div>
			<IonButton fill="clear" onClick={() => setShowAlert(true)} disabled={disabled}>
				<GarbageIcon />
			</IonButton>
			<IonModal
				isOpen={showAlert}
				className={classes.deleteCardModal}
				onDidDismiss={handleAlertCancel}
			>
				<div className={classes.modalWrapper}>
					<h3 className={classes.modalTitle}>
						{intl.formatMessage({
							id: "do-you-want-sign-out",
							defaultMessage: "Are you sure you want to delete?",
						})}
					</h3>
					<div className={classes.modalButtons}>
						<div className={clsx(classes.modalButtonWrapper, classes.modalButtonFirst)}>
							<IonButton
								className={classes.modalButton}
								fill="clear"
								onClick={handleAlertCancel}
							>
								{intl.formatMessage({
									id: "cancel-sign-out",
									defaultMessage: "Cancel",
								})}
							</IonButton>
						</div>
						<div className={classes.modalButtonWrapper}>
							<IonButton
								className={classes.modalButton}
								fill="clear"
								onClick={handleAlertOk}
							>
								{intl.formatMessage({
									id: "sign-out-ok",
									defaultMessage: "Ok",
								})}
							</IonButton>
						</div>
					</div>
				</div>
			</IonModal>
		</div>
	)
}
