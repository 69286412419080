import { SyntheticEvent, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { IonButton, IonPopover, IonRadio, IonRadioGroup } from "@ionic/react"
import { useUnit } from "effector-react"
import clsx from "clsx"

import { $selectedEventsSortValue, $selectedVCardsSortValue, setSelectedEventsSortValue, setSelectedVCardsSortValue } from "../store/events"
import { $vCards, sortOrder } from "../store/events/events-units"
import { FilterIcon } from "./filter-icon"
import classes from "./sort-component.module.css"
interface Prop {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleSort: any
	isEventsSort: boolean
}
export default function SortComponent({ handleSort, isEventsSort }: Prop) {
	const intl = useIntl()
	const popover = useRef<HTMLIonPopoverElement>(null)
	const [isPopoverOpen, setIsPopoverOpen] = useState(false)
	const { selectedEventsSortValue, selectedVCardsSortValue, vCards } = useUnit({
		selectedEventsSortValue: $selectedEventsSortValue,
		selectedVCardsSortValue: $selectedVCardsSortValue,
		vCards: $vCards,
	})

	const sortValue = isEventsSort ? selectedEventsSortValue : selectedVCardsSortValue

	const openPopover = (e: SyntheticEvent) => {
		if (popover.current) {
			popover.current.event = e
			setIsPopoverOpen(true)
		}
	}

	const handleRadioChange = (event: { detail: { value: typeof sortOrder } }) => {
		isEventsSort ? setSelectedEventsSortValue(event.detail.value) : setSelectedVCardsSortValue(event.detail.value)
		setIsPopoverOpen(false)
		handleSort()
	}

	return (
		<div>
			<IonButton onClick={openPopover} fill="clear" disabled={isEventsSort ? false : vCards.owner.length === 0}>
				<FilterIcon />
			</IonButton>
			<IonPopover
				ref={popover}
				isOpen={isPopoverOpen}
				className={classes.eventsPopover}
				onDidDismiss={() => setIsPopoverOpen(false)}
			>
				<IonRadioGroup value={sortValue} onIonChange={handleRadioChange}>
					<div className={classes.sort}>
						<div className={classes.vcardCheckboxSort}>
							<IonRadio
								value="newest"
								className={clsx(
									classes.sortCheckbox,
									classes.radioButton,
									sortValue === "newest" && classes.radioButtonChecked
								)}
								aria-checked
							>
								{intl.formatMessage({
									id: "sorting-newest",
									defaultMessage: "Newest",
								})}
							</IonRadio>
						</div>
						<div className={classes.vcardCheckboxSort}>
							<IonRadio
								value="oldest"
								className={clsx(
									classes.sortCheckbox,
									classes.radioButton,
									sortValue === "oldest" && classes.radioButtonChecked
								)}
							>
								{intl.formatMessage({
									id: "sorting-oldest",
									defaultMessage: "Oldest",
								})}
							</IonRadio>
						</div>
						<div className={classes.vcardCheckboxSort}>
							<IonRadio
								value="a-z"
								className={clsx(
									classes.sortCheckbox,
									classes.radioButton,
									sortValue === "a-z" && classes.radioButtonChecked
								)}
							>
								{intl.formatMessage({
									id: "sorting-a-z",
									defaultMessage: "A-Z",
								})}
							</IonRadio>
						</div>
						<div className={classes.vcardCheckboxSort}>
							<IonRadio
								value="z-a"
								className={clsx(
									classes.sortCheckboxSecond,
									classes.radioButton,
									sortValue === "z-a" && classes.radioButtonChecked
								)}
							>
								{intl.formatMessage({
									id: "sorting-z-a",
									defaultMessage: "Z-A",
								})}
							</IonRadio>
						</div>
					</div>
				</IonRadioGroup>
			</IonPopover>
		</div>
	)
}
