/* eslint-disable effector/no-getState */
import { createEvent } from "effector"
import { createGate } from "effector-react"

import { api } from "../../api"
import { ConferenceEvent, Contact, DownloadParams, EventStatus, vCards } from "../../domain/events"
import { assert } from "../../lib/assert"
import { appDomain } from "../app"

const domain = appDomain.createDomain("events")

export const EventsScreenGate = createGate({ domain })

export const $events = domain.createStore<Array<ConferenceEvent>>([])
export const $vCards = domain.createStore<vCards>({
	title: "",
	owner: [],
	active: true,
})

export const isFormExpandedChanged = domain.createEvent<boolean>()
export const $isFormExpanded = domain.createStore<boolean>(false)

export const isEventIdChanged = domain.createEvent<boolean>()
export const $isEventChanged = domain.createStore<boolean>(false)

export const isUpdateAvailable = domain.createEvent()
export const updateOnShare = createEvent<boolean>()

export const $isUpdate = domain.createStore(false)

export const shareButtonDisplay = domain.createEvent<boolean>()
export const $isShareButtonVisible = domain.createStore(true)

export const isFieldUpdated = domain.createEvent<boolean>()
export const $fieldUpdate = domain.createStore(false)

export let sortOrder: 'newest' | 'oldest' | 'z-a' | 'a-z'

export const $selectedEventsSortValue = domain.createStore<typeof sortOrder>('newest')
export const setSelectedEventsSortValue = domain.createEvent<typeof sortOrder>()

export const $selectedVCardsSortValue = domain.createStore<typeof sortOrder>('newest')
export const setSelectedVCardsSortValue = domain.createEvent<typeof sortOrder>()

export const fetchSortedEventsFx = domain.createEffect(async () => {
	const isNewest = $selectedEventsSortValue.getState() === 'newest'
	const isOldest = $selectedEventsSortValue.getState() === "oldest"
	const isZA = $selectedEventsSortValue.getState() === "z-a"

	const wasChronOrAlpha = isNewest || isOldest ? 'id' : 'title'
	const wasDescOrAsc = isNewest || isZA ? 'desc' : 'asc'
	const query = isNewest ? '' : `?sort=${wasChronOrAlpha},${wasDescOrAsc}`

	const response = await api.get(`events/annual${query}`)
	assert(response.ok, `Failed to fetch events: ${response.status} ${response.statusText}`)

	try {
		const events = (await response.json()) as Array<ConferenceEvent>
		return events
	} catch (error) {
		throw new Error("Failed to parse response body")
	}
})

export const fetchSortedVCardsFx = domain.createEffect(async (eventId: number) => {
	const isNewest = $selectedVCardsSortValue.getState() === 'newest'
	const isOldest = $selectedVCardsSortValue.getState() === "oldest"
	const isZA = $selectedVCardsSortValue.getState() === "z-a"

	const wasChronOrAlpha = isNewest || isOldest ? 'id' : 'name'
	const wasDescOrAsc = isNewest || isZA ? 'desc' : 'asc'
	const query = isNewest ? '' : `?sort=${wasChronOrAlpha},${wasDescOrAsc}`

	const response = await api.get(
		`vcards/vcard-users/${eventId}${query}`
	)
	assert(response.ok, `Failed to fetch events: ${response.status} ${response.statusText}`)
	try {
		const vCards = await response.json()
		return vCards as vCards
	} catch (error) {
		throw new Error("Failed to parse response body")
	}
})

export const downloadAllCardFx = domain.createEffect(async (eventId: number) => {
	const response = await api.get(`csv/event/${eventId}`)
	assert(response.ok, `Failed to fetch events: ${response.status} ${response.statusText}`)

	try {
		const downloadCsvFile = await response.text()
		return downloadCsvFile
	} catch (error) {
		throw new Error("Failed to parse response body")
	}
})
export const downloadSpecificCardFx = domain.createEffect(async (props: DownloadParams) => {
	const { eventId, vCardsId } = props
	const response = await api.get(`csv/vcards?ids=${vCardsId}&eventId=${eventId}`)
	assert(response.ok, `Failed to fetch events: ${response.status} ${response.statusText}`)

	try {
		const downloadCsvFile = await response.text()
		return downloadCsvFile
	} catch (error) {
		throw new Error("Failed to parse response body")
	}
})

export const SendCardFx = domain.createEffect(async (name: string) => {
	// can be implemented in a better way. can we use some kind of library for dates?
	const endDateTime = new Date(new Date().getTime() + 2 * 24 * 60 * 60 * 1000).toISOString()
	// guram suggest than for now this variables should be static except name
	const requestBody = {
		name,
		status: "ONGOING",
		active: true,
		startDateTime: new Date().toISOString(),
		endDateTime,
	}

	return api.post(`events`, { json: requestBody })
})

export const statusCardFx = domain.createEffect(async (props: EventStatus) => {
	const { eventId, active } = props

	const requestBody = {
		active,
	}
	return api.patch(`events/${eventId}`, { json: requestBody })
})

export const deleteVCardsFx = domain.createEffect(async (vCardIds: string) => {
	const response = await api.delete(`vcards?ids=${vCardIds}`)
	assert(response.ok, `Failed to delete events: ${response.status} ${response.statusText}`)
	try {
		return "delete Successfully"
	} catch (error) {
		throw new Error("Failed to handle response data after deletion")
	}
})

export const deleteEventsFx = domain.createEffect(async (eventIds: string) => {
	const response = await api.delete(`events?ids=${eventIds}`)
	assert(response.ok, `Failed to delete events: ${response.status} ${response.statusText}`)
	try {
		return "delete Successfully"
	} catch (error) {
		throw new Error("Failed to handle response data after deletion")
	}
})

export const contactSelected = createEvent<Contact>()
