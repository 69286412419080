type Extra = {
	[key: string]: unknown
}

export function trackError(
	message: string,
	error: Error | unknown = new Error(message),
	extra: Extra = {}
): void {
	try {
		console.error(message, { error, extra })
		// TODO: send error to Sentry
	} catch {
		// do nothing
	}
}
