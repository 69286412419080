export function tryParseJson<T, D>(json: unknown, defaultValue?: D): T | D {
	if (typeof json !== "string") {
		return defaultValue as D
	}

	try {
		return JSON.parse(json)
	} catch (e) {
		return defaultValue as D
	}
}
