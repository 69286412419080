import { useState } from "react"
import { useIntl } from "react-intl"
import { IonButton, IonCheckbox, IonInput, IonItem, IonList, IonModal } from "@ionic/react"
import { useUnit } from "effector-react"
import clsx from "clsx"

import SortComponent from "../sort-component/sort-component"
import { $currentEventId, eventId } from "../store/editor"
import {
	$events,
	deleteEventsFx,
	fetchSortedEventsFx,
	fetchSortedVCardsFx,
	isEventIdChanged,
	SendCardFx,
} from "../store/events"
import { DeleteVcard } from "./delete-vcard"
import classes from "./events-section.module.css"

export function EventsSection() {
	const [isOkDisabled, setIsOkDisabled] = useState(true)
	const [showAlert, setShowAlert] = useState(false)
	const [inputValue, setInputValue] = useState("")
	const [selectedEvents, setSelectedEvents] = useState<number[]>([])

	const intl = useIntl()
	const { eventsData, currentEventId } = useUnit({
		eventsData: $events,
		currentEventId: $currentEventId
	 })

	const handleAlertCancel = () => {
		setShowAlert(false)
		setInputValue("")
		setIsOkDisabled(true)
	}

	const handleAlertOk = () => {
		setShowAlert(false)
		SendCardFx(inputValue)
		setInputValue("")
	}
	const handleFetchVCards = (id: number) => {
		fetchSortedVCardsFx(id)
		eventId(id)
		isEventIdChanged(true)
	}
	const handleCheckboxChange = (eventId: number) => {
		setSelectedEvents((prevSelectedEvents) => {
			const updatedSelectedEvents = prevSelectedEvents.includes(eventId)
				? prevSelectedEvents.filter((id) => id !== eventId)
				: [...prevSelectedEvents, eventId]
			return updatedSelectedEvents
		})
		isEventIdChanged(false)
	}
	const selectedEventsByComma = selectedEvents.join(",")
	const handleDeleteEvent = () => {
		if (selectedEvents.length === 0) {
			return
		}
		deleteEventsFx(selectedEventsByComma)
		setSelectedEvents([])
	}
	return (
		<div className={classes.root}>
			<div className={classes.headingSticky}>
				<h2 className={classes.heading}>
					{intl.formatMessage({
						id: "events-title",
						defaultMessage: "Events",
					})}
				</h2>
				<div className={classes.actionBox}>
					<DeleteVcard
						disabled={selectedEvents.length === 0}
						handleDeleteEvents={() => handleDeleteEvent()}
					/>
					<SortComponent handleSort={() => fetchSortedEventsFx()} isEventsSort />
				</div>
			</div>
			{eventsData.map((eventYear) => (
				<div key={eventYear.year}>
					<h4 className={classes.yearGroupHeading}>{eventYear.year}</h4>

					<IonList className={classes.eventList}>
						{eventYear.events.map((event) => (
							<IonItem lines="none" className={classes.ionItem} key={event.id}>
								<div
									className={
										currentEventId === event.id
											? classes.cardCurrentId
											: classes.eventBox
									}
								>
									<div className={classes.eventCheckboxContainer}>
										<IonCheckbox
											className={classes.eventCheckbox}
											checked={selectedEvents.includes(event.id)}
											onIonChange={() => handleCheckboxChange(event.id)}
										/>
										<IonButton
											fill="clear"
											onClick={() => handleFetchVCards(event.id)}
											className={classes.eventCheckboxButton}
										>
											<div className={classes.cardBox}>
												<h3 className={classes.cardBoxTitle}>
													{event.title}
												</h3>
												<p className={classes.cardBoxDescription}>
													{intl.formatMessage(
														{
															id: "events-year-group-item-vCard-quantity",
															defaultMessage:
																"{count, plural, =0 {No vCards} other {# vCards}}",
														},
														{ count: event.vcardCount }
													)}
												</p>
											</div>
										</IonButton>
									</div>
								</div>
							</IonItem>
						))}
					</IonList>
				</div>
			))}
			<IonButton
				onClick={() => setShowAlert(true)}
				shape="round"
				className={classes.newEvent}
			>
				{intl.formatMessage({
					id: "events-new-event-button",
					defaultMessage: "New Event",
				})}
			</IonButton>
			<IonModal
				isOpen={showAlert}
				className={classes.newEventModal}
				onDidDismiss={handleAlertCancel}
			>
				<div className={classes.modalWrapper}>
					<h3 className={classes.modalTitle}>
						{intl.formatMessage({
							id: "event-name",
							defaultMessage: "Event Name",
						})}
					</h3>
					<p className={classes.modalDescription}>
						{intl.formatMessage({
							id: "enter-the-name-of-the-event",
							defaultMessage: "Enter the name of the event",
						})}
					</p>
					<div className={classes.modalInputWrapper}>
						<IonInput
							className={classes.modalInput}
							value={inputValue}
							onIonInput={(e) => {
								if (String(e.detail.value).trim() === '') {
									setIsOkDisabled(true)
									setInputValue(String(e.detail.value))
								} else {
									setIsOkDisabled(false)
									setInputValue(String(e.detail.value))
								}
							}}
						/>
					</div>
					<div className={classes.modalButtons}>
						<div className={clsx(classes.modalButtonWrapper, classes.modalButtonFirst)}>
							<IonButton
								className={classes.modalButton}
								fill="clear"
								onClick={handleAlertCancel}
							>
								{intl.formatMessage({
									id: "events-modal-cancel",
									defaultMessage: "Cancel",
								})}
							</IonButton>
						</div>
						<div className={classes.modalButtonWrapper}>
							<IonButton
								className={classes.modalButton}
								fill="clear"
								onClick={handleAlertOk}
								disabled={isOkDisabled}
							>
								{intl.formatMessage({
									id: "events-modal-ok",
									defaultMessage: "OK",
								})}
							</IonButton>
						</div>
					</div>
				</div>
			</IonModal>
		</div>
	)
}
