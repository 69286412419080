import { isPlatform } from "@ionic/react"
import { z } from "zod"

const EnvVarsSchema = z
	.object({
		API_URL: z.string(),
		SHEET_URL: z.string(),
		CLIENT_ID: z.string(),
		AUTHORITY: z.string(),
	})
	.strict()

const vars = EnvVarsSchema.parse(window.__ENV__)

export const config = {
	isWeb: isPlatform("desktop") || isPlatform("mobileweb"),
	isNativeApp: isPlatform("ios") || isPlatform("ipad"),

	apiUrl: vars.API_URL,
	sheetUrl: vars.SHEET_URL,

	msal: {
		auth: {
			clientId: vars.CLIENT_ID,
			authority: vars.AUTHORITY,
			redirectUri: window.location.origin + "/auth",
		},

		cache: {
			cacheLocation: "localStorage",
		},
	},

	qrCode: {
		defaultImage: "./qr-code-logo.png",
		alignmentPadding: 40,
		size: 600,
		quietZone: 40,
		genTimeout: 500,
		rotatable: false,
		scalable: true,
	},

	bg: {
		rotatable: false,
		scalable: true,
	},

	canvas: {
		// iPhone 14 Pro screen resolution
		width: 1179,
		height: 2556,
	},

	// used to store contacts in storage for later sync
	newContactsStoreKey: "contacts",

	trySyncEvery: 1000 * 60 * 1, // 1 minute

	snapToCenterRadius: 50,
}
