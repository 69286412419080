import { ChangeEvent, useRef, useState } from "react"
import { useIntl } from "react-intl"
import { IonButton, IonList, IonModal } from "@ionic/react"
import { useUnit } from "effector-react"

import { config } from "../config"
import { $presets, presetChanged } from "../store/editor"
import { ExpandedGalleryModal } from "./expanded-gallery-modal"
import classes from "./presets.module.css"

export function Presets() {
	const [showModal, setShowModal] = useState(false)
	const [showCameraModal, setShowCameraModal] = useState(false)
	const videoRef = useRef<HTMLVideoElement>(null)
	const canvasRef = useRef<HTMLCanvasElement>(null)
	const fileInputRef = useRef<HTMLInputElement>(null)
	const intl = useIntl()
	const list = useUnit($presets)

	const handleTurnOffCamera = () => {
		if (videoRef.current && videoRef.current.srcObject) {
		  const stream = videoRef.current.srcObject as MediaStream
		  const tracks = stream.getTracks()

		  tracks.forEach((track) => track.stop())
		  videoRef.current.srcObject = null
		}
	  }

	const handleTakePhotoClick = async () => {
		setShowModal(false)
		setShowCameraModal(true)
		try {
		  const stream = await navigator.mediaDevices.getUserMedia({ video: true })

		  if (videoRef.current) {
			videoRef.current.srcObject = stream
		  }
		} catch (error) {
		  console.error('Error accessing camera:', error)
		}
	  }

	  const handleCaptureClick = () => {
		if (videoRef.current && canvasRef.current) {
		  const video = videoRef.current
		  const canvas = canvasRef.current

		  canvas.width = video.videoWidth
		  canvas.height = video.videoHeight

		  canvas.getContext('2d')?.drawImage(video, 0, 0, canvas.width, canvas.height)

		  const dataUrl = canvas.toDataURL('image/jpeg')
		  presetChanged({ url: dataUrl })
		}

		handleCameraModalClose()
	  }

	const handleCameraModalClose = () => {
		handleTurnOffCamera()
		setShowCameraModal(false)
	}

	const handleFileInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0]

		if (file && file.type.startsWith('image/')) {
		  const imageUrl = URL.createObjectURL(file)

		  setShowModal(false)
		  presetChanged({ url: imageUrl })
		}
	}

	return (
		<div className={classes.root}>
			<div className={classes.headingBox}>
				<h2 className={classes.heading}>
					{intl.formatMessage({
						id: "editor-presets-title",
						defaultMessage: "Background presets",
					})}
				</h2>
				<ExpandedGalleryModal />
			</div>
			<div className={classes.presets}>
				{list
					.filter((preset) => preset.url !== "")
					.map((preset) => (
						<div
							key={preset.url}
							className={classes.image}
							style={{ backgroundImage: `url(${preset.url}` }}
							onClick={() => presetChanged(preset)}
						/>
					))}
			</div>

			{config.isWeb ? (
				<IonButton
					className={classes.pickImageButton}
					onClick={() => setShowModal(true)}
					expand="block"
				>
					{intl.formatMessage({
						id: "editor-presets-pick-image",
						defaultMessage: "Choose from gallery",
					})}
				</IonButton>
			): (
				<div>
					<input
						type="file"
						className={classes.modalListInput}
						ref={fileInputRef}
						onChange={handleFileInputChange}
					/>
					<IonButton
						className={classes.pickImageButton}
						onClick={() => fileInputRef.current?.click()}
						expand="block"
					>
						{intl.formatMessage({
							id: "editor-presets-pick-image",
							defaultMessage: "Choose from gallery",
						})}
					</IonButton>
				</div>
			)}

			<IonModal
				isOpen={showCameraModal}
				onDidDismiss={handleCameraModalClose}
			>
				<div className={classes.cameraModalWrapper}>
					<video width="100%" ref={videoRef} autoPlay />
					<canvas ref={canvasRef} style={{ display: 'none' }} />
					<IonButton size="small" onClick={handleCaptureClick}>Take Photo</IonButton>
				</div>
			</IonModal>

			<IonModal
				isOpen={showModal}
				className={classes.chooseFromGalleryModal}
				onDidDismiss={() => setShowModal(false)}
			>
				<div className={classes.modalContent}>
					<IonList class={classes.modalList}>
						<IonButton
							className={classes.modalListButton}
							onClick={handleTakePhotoClick}
						>
							{intl.formatMessage({
								id: "editor-presets-taka-a-photo",
								defaultMessage: "Take a photo",
							})}
						</IonButton>
						<div>
							<input
								type="file"
								className={classes.modalListInput}
								ref={fileInputRef}
								onChange={handleFileInputChange}
							/>
							<IonButton className={classes.modalListButton} onClick={() => fileInputRef.current?.click()}>
								{intl.formatMessage({
									id: "editor-presets-choose-from-files",
									defaultMessage: "Choose from files",
								})}
							</IonButton>
						</div>
					</IonList>
				</div>
			</IonModal>
		</div>
	)
}
