import { z } from "zod"

export const DateIsoUtcSchema = z.string().datetime()

export type DateIso = z.infer<typeof DateIsoUtcSchema>

export const ContactSchema = z
	.object({
		firstName: z.string().trim().nonempty(),
		lastName: z.string().trim().nonempty(),
		email: z.string().email(),
		phone: z.string().nullish(),
		position: z.string().nullish(),
		company: z.string().nullish(),
		website: z.string().nullish(),
		address1: z.string().nullish(),
		address2: z.string().nullish(),
		state: z.string().nullish(),
		postalCode: z.string().nullable(),
		city: z.string().nullish(),
		country: z.string().nullish(),
		acceptedProcessingAt: DateIsoUtcSchema.nullable(),
		acceptedPromotionsAt: DateIsoUtcSchema.nullable(),
	})
	.strict()

export type Contact = z.infer<typeof ContactSchema>

// TODO: implement with zod
export type Interest = {
	topics: string[]
	id?: number
	accountManager: string
	reconnectionAmount: number
	additionalData: string
	reconnectionPeriod: 'DAY' | 'WEEK' | 'MONTH' | ''
}
// Grid 3x3 and free position
export type AlignmentPosition =
	| "free"
	| "1:1"
	| "1:2"
	| "1:3"
	| "2:1"
	| "2:2"
	| "2:3"
	| "3:1"
	| "3:2"
	| "3:3"

export type Preset = {
	url: string
	alignment?: AlignmentPosition
	qrCodeAlignment?: AlignmentPosition
}
