/* eslint-disable effector/no-getState */
import { RefObject } from "react"
import { isPlatform } from "@ionic/react"
import { Haptics, ImpactStyle } from "@capacitor/haptics"
import { Preferences } from "@capacitor/preferences"
import { attach, merge } from "effector"
import { createGate } from "effector-react"
import { debounce } from "patronum"
import fileSave from "file-saver"
import TouchEmulator from "hammer-touchemulator"
import Konva from "konva"
import objectHash from "object-hash"

import { api } from "../../api"
import { config } from "../../config"
import { AlignmentPosition, Contact, ContactSchema, Interest, Preset } from "../../domain/editor"
import { assert } from "../../lib/assert"
import { createImage } from "../../lib/create-image"
import { generateQrCodeImage } from "../../lib/generate-qr-code"
import { trackError } from "../../lib/track-error"
import { tryParseJson } from "../../lib/try-parse-json"
import { wait } from "../../lib/wait"
import { waitFor } from "../../lib/wait-for"
import { Scan, ScanResult } from "../../scan"
import { Share } from "../../share"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Hammer from "../../vendor/hammerjs+konva"
import { appDomain } from "../app"
import { $token } from "../auth"
import { $events } from "../events"

const domain = appDomain.createDomain("editor")

export const EditorScreenGate = createGate<{ vCardId: string | null }>()
export const StageGate = createGate<RefObject<Konva.Stage>>({ domain })
export const BackgroundImageGate = createGate<RefObject<Konva.Image>>({ domain })
export const QrCodeImageGate = createGate<RefObject<Konva.Image>>({ domain })
export const PreviewContainerGate = createGate<RefObject<HTMLDivElement>>({ domain })

export const newVcardOnFormPressed = domain.createEvent()
export const clearOldFeedback = domain.createEvent()
export const shareClicked = domain.createEvent()
export const shareViaEmailClicked = domain.createEvent()
export const scanCardClicked = domain.createEvent()

export const presetChanged = domain.createEvent<Preset>()

export const formValuesChanged = domain.createEvent<Partial<Contact>>()

export const qrCodeImageDragMoved = domain.createEvent()
export const qrCodeImageDragEnded = domain.createEvent()
export const $isNearCenter = domain.createStore<boolean>(false)

const $logo = domain.createStore<string>(config.qrCode.defaultImage)

export const $presets = domain.createStore<Array<Preset>>([
	{ url: "" },
	{ url: "./backgrounds/1.jpg" },
	{ url: "./backgrounds/2.jpg" },
	{ url: "./backgrounds/3.jpg" },
	{ url: "./backgrounds/4.jpg" },
	{ url: "./backgrounds/5.jpg" },
	{ url: "./backgrounds/6.jpg" },
	{ url: "./backgrounds/7.jpg" },
	{ url: "./backgrounds/8.jpg" },
	{ url: "./backgrounds/9.jpg" },
	{ url: "./backgrounds/10.jpg" },
	{ url: "./backgrounds/11.jpg" },
	{ url: "./backgrounds/12.jpg" },
	{ url: "./backgrounds/13.jpg" },
	{ url: "./backgrounds/14.jpg" },
	{ url: "./backgrounds/15.jpg" },
	{ url: "./backgrounds/16.jpg" },
])

// eslint-disable-next-line effector/no-getState
export const $preset = domain.createStore<Preset>($presets.getState()[0])
export const $backgroundImage = domain.createStore<HTMLImageElement | null>(null)
export const clearBackgroundImage = domain.createEvent<HTMLImageElement | null>()
export const updateBackgroundImage = domain.createEvent<HTMLImageElement | null>()

export const $qrCodeImage = domain.createStore<HTMLImageElement | null>(null)
export const qrCodeAlignmentChanged = domain.createEvent<AlignmentPosition>()
export const bgAlignmentChanged = domain.createEvent<AlignmentPosition>()

export const $qrCodeAlignment = domain.createStore<AlignmentPosition>("3:2")
export const $bgAlignment = domain.createStore<AlignmentPosition>("2:2")

export const $isLogoShown = domain.createStore<boolean>(true)
export const isLogoShownChanged = domain.createEvent<boolean>()

export const $isOverlayShown = domain.createStore<boolean>(true)
export const isOverlayShownChanged = domain.createEvent<boolean>()

export const $formValues = domain.createStore<Contact>({
	firstName: "",
	lastName: "",
	email: "",
	phone: "",
	company: "",
	position: "",
	website: "",
	address1: "",
	address2: "",
	state: "",
	postalCode: "",
	country: "",
	city: "",
	acceptedProcessingAt: null,
	acceptedPromotionsAt: null,
})
export const $feedbackData = domain.createStore<Interest>({
	additionalData: "",
	reconnectionAmount: 0,
	id: 0,
	accountManager: "",
	reconnectionPeriod: "",
	topics: [],
})

export const $scannedCardContent = domain.createStore<ScanResult["content"]>([])

export const $formValuesSuggestions = $scannedCardContent.map((item) => item.map((i) => i.value))

export const $scannedImages = domain.createStore<ScanResult["images"]>([])

export const $isFormValid = $formValues.map(
	(values) => ContactSchema.safeParse(values).success && values.acceptedProcessingAt !== null
)

const $vCard = $formValues.map((v) => {
	return `
BEGIN:VCARD
VERSION:3.0
N:${v.lastName};${v.firstName}
FN:${v.firstName} ${v.lastName}
TITLE:${v.position}
ORG:${v.company}
EMAIL;TYPE=INTERNET:${v.email}
ADDRESS1:${v.address1}
ADDRESS2:${v.address2}
STATE:${v.state}
POSTALCODE:${v.postalCode}
TEL;TYPE=voice,cell,pref:${v.phone}
ADR:;;;${v.city};;;${v.country}
END:VCARD
`
})

export const isFormExpandedChanged = domain.createEvent<boolean>()
export const $isFormExpanded = domain.createStore<boolean>(false)

export const eventId = domain.createEvent<number>()
export const $currentEventId = domain.createStore<number>(0)

export const vCardId = domain.createEvent<number>()
export const $currentVCardId = domain.createStore<number>(0)

export const showFeedBack = domain.createEvent<boolean>()
export const $isFeedbackShown = domain.createStore<boolean>(false)

export const setJustCreatedVCardId = domain.createEvent<number>()
export const $justCreatedVCardId = domain.createStore<number>(0)

export const shareWallpaperFx = attach({
	source: { canvasRef: StageGate.state },
	effect: async ({ canvasRef }) => {
		const stage = canvasRef.current
		assert(stage, "Stage is not ready")

		const fileName = "wallpaper.png"

		// convert canvas to blob
		const blob = await new Promise<Blob | null>((resolve) => {
			stage.toBlob({ mimeType: "image/png", quality: 1, callback: (blob) => resolve(blob) })
		})

		assert(blob, "Blob is not created")

		const file = new File([blob], fileName, { type: "image/png" })

		if ("share" in navigator) {
			return await navigator.share({ title: "Wallpaper", files: [file] })
		}

		fileSave(blob, fileName)
	},
})

export const shareViaEmailFx = attach({
	source: { canvasRef: StageGate.state, values: $formValues },
	effect: async ({ canvasRef, values }) => {
		const stage = canvasRef.current
		assert(stage, "Stage is not ready")

		const dataURL = stage.toDataURL({
			mimeType: "image/png",
			quality: 0,
			pixelRatio: 2,
		})

		const base64 = dataURL.split(",")[1]

		await Share.sendEmail({
			base64,
			email: values.email,
			subject: "Your virtual business card wallpaper",
			fileName: "wallpaper.png",
		})
	},
})

export const createBackgroundImageFx = domain.createEffect(async (url: string) => {
	const image = await createImage(url)

	const scale = Math.max(config.canvas.width / image.width, config.canvas.height / image.height)

	if (scale > 3) {
		// if image is too small, just return it
		return image
	}

	// scale image to fill canvas and keep aspect ratio
	const width = image.width * scale
	const height = image.height * scale

	// change image element size
	image.width = width
	image.height = height

	return image
})

export const createQrCodeFx = attach({
	source: { vCard: $vCard, logo: $logo, withLogo: $isLogoShown },
	effect: async ({ vCard, logo, withLogo }) => {
		return await generateQrCodeImage({
			text: vCard,
			size: config.qrCode.size,
			logo: withLogo ? logo : null,
		})
	},
})

export const syncNewContactsFx = domain.createEffect(async () => {
	const list = await readStoredNewContacts()
	for (const contact of list) {
		if ($currentVCardId.getState() !== 0 || $justCreatedVCardId.getState() !== 0) {
			await updateVcardFx(contact)
		} else {
			await createNewVcardFx(contact)
		}
	}

	const currentList = await readStoredNewContacts()

	assert(objectHash(list) === objectHash(currentList), "Contacts list changed during sync")

	clearStoredNewContacts()
})

export const storeContactFx = domain.createEffect(async (contact: Contact) => {
	const list = await readStoredNewContacts()
	list.push(contact)

	const newValue = JSON.stringify(list)

	await Preferences.set({ key: config.newContactsStoreKey, value: newValue })
})

export const qrCodeGenTriggered = debounce({
	source: merge([$formValues.updates, StageGate.open, $backgroundImage.updates]),
	timeout: config.qrCode.genTimeout,
})

export const fitStageIntoParentContainerFx = attach({
	source: { stageRef: StageGate.state, containerRef: PreviewContainerGate.state },
	effect: async ({ stageRef, containerRef }) => {
		await wait(100)
		const stage = stageRef.current
		const container = containerRef.current
		if (!stage || !container) {
			return
		}
		// now we need to fit stage into parent
		const containerWidth = container.offsetWidth
		// to do this we need to scale the stage
		const scale = containerWidth / stage.width()

		const konvaContentWrapper = container.firstChild as HTMLElement

		assert(konvaContentWrapper, "'.konvajs-content' wrapper element is not found")

		konvaContentWrapper.style.cssText = `
			transform: scale(${scale});
			transform-origin: top left;
		`
	},
})

export const initGesturesRecognizerFx = attach({
	source: {
		stageRef: StageGate.state,
		bgRef: BackgroundImageGate.state,
		qrRef: QrCodeImageGate.state,
	},
	effect: async ({ stageRef, bgRef, qrRef }) => {
		await wait(2000)

		const stage = stageRef.current
		const bg = bgRef.current
		const qr = qrRef.current

		if (!stage || !bg || !qr) {
			return
		}

		Konva.hitOnDragEnabled = true
		Konva.capturePointerEventsEnabled = true

		if (isPlatform("desktop")) {
			// emulate touch events for desktop
			TouchEmulator()
		}

		initGestures(qr, { rotatable: false, scalable: true }, 1.65, 0.8)
		initGestures(bg, { rotatable: false, scalable: true }, 1.65, 0.8)

		let active: Konva.Image | null = null

		function initGestures(
			image: Konva.Image,
			options: { rotatable?: boolean; scalable?: boolean } = {},
			maxScaleX: number,
			minScaleX: number,
		) {
			const { rotatable = true, scalable = true } = options
			const id = image.id()

			const manager = new Hammer(image, { domEvents: true })
			manager.get("rotate").set({ enable: true })

			let oldRotation = 0
			let startScale = 0

			type Ev = Konva.KonvaEventObject<Konva.Image> & {
				evt: { gesture: { rotation: number; scale: number } }
			}

			image.on("rotatestart", function (event: Ev) {
				if (active !== null) {
					return
				}

				active = image
				oldRotation = event.evt.gesture.rotation
				startScale = image.scaleX()
				image.stopDrag()
				imagesDraggable(false)
			})

			image.on("rotate", function (event: Ev) {
				if (active?.id() !== id) {
					return
				}

				if (rotatable) {
					const delta = oldRotation - event.evt.gesture.rotation
					image.rotate(-delta)
					oldRotation = event.evt.gesture.rotation
				}

				if (scalable) {
					// scale image and keep the center in the same place
					const newScale = {
						x: startScale * event.evt.gesture.scale,
						y: startScale * event.evt.gesture.scale,
					}

					const dx = (image.width() * (newScale.x - image.scaleX())) / 2
					const dy = (image.height() * (newScale.y - image.scaleY())) / 2

					if (newScale.x > minScaleX && newScale.x < maxScaleX) {
						image.scaleX(newScale.x)
						image.scaleY(newScale.y)

						image.x(image.x() - dx)
						image.y(image.y() - dy)
					}

				}
			})

			image.on("rotateend rotatecancel", function (_event: Ev) {
				if (active === image) {
					active = null
				}

				imagesDraggable(true)
			})
		}

		function imagesDraggable(draggable: boolean) {
			;[bg, qr].forEach((image) => image?.draggable(draggable))
		}
	},
})

export const alignQrCodeFx = attach({
	source: {
		stageRef: StageGate.state,
		qrRef: QrCodeImageGate.state,
	},
	effect: async ({ stageRef, qrRef }, position: AlignmentPosition) => {
		await waitFor(() => {
			if (stageRef.current && qrRef.current) {
				return true
			}
		})

		const stage = stageRef.current
		const image = qrRef.current

		if (!image || !stage) {
			return
		}

		const stageRect = {
			width: stage.width() / stage.scaleX(),
			height: stage.height() / stage.scaleY(),
		}

		const imageRect = image.getClientRect({ relativeTo: stage })

		const p = config.qrCode.alignmentPadding

		if (position === "1:1") {
			// top left
			image.to({ x: 0 + p, y: 0 + p })
		}

		if (position === "1:2") {
			// top center
			image.to({ x: stageRect.width / 2 - imageRect.width / 2, y: 0 + p })
		}

		if (position === "1:3") {
			// top right
			image.to({ x: stageRect.width - imageRect.width - p, y: 0 + p })
		}

		if (position === "2:1") {
			// center left
			image.to({ x: 0 + p, y: stageRect.height / 2 - imageRect.height / 2 })
		}

		if (position === "2:2") {
			// center
			const x = stageRect.width / 2 - imageRect.width / 2
			const y = stageRect.height / 2 - imageRect.height / 2

			if (image.opacity() === 0) {
				// initially it is hidden
				image.x(x)
				image.y(y)
				image.opacity(1)
				return
			}

			image.to({ x, y })
		}

		if (position === "2:3") {
			// center right
			image.to({
				x: stageRect.width - imageRect.width - p,
				y: stageRect.height / 2 - imageRect.height / 2,
			})
		}

		const paddingBottom = stageRect.height * 0.15

		if (position === "3:1") {
			// bottom left
			image.to({
				x: 0 + p,
				y: stageRect.height - imageRect.height - paddingBottom,
			})
		}

		if (position === "3:2") {
			// bottom center
			image.to({
				x: stageRect.width / 2 - imageRect.width / 2,
				y: stageRect.height - imageRect.height - paddingBottom,
			})
		}

		if (position === "3:3") {
			// bottom right
			image.to({
				x: stageRect.width - imageRect.width - p,
				y: stageRect.height - imageRect.height - paddingBottom,
			})
		}
	},
})

export const alignBgFx = attach({
	source: {
		stageRef: StageGate.state,
		bgRef: BackgroundImageGate.state,
	},
	effect: async ({ stageRef, bgRef }, position: AlignmentPosition) => {
		await waitFor(() => {
			if (stageRef.current && bgRef.current) {
				return true
			}
		})

		const stage = stageRef.current
		const image = bgRef.current

		if (!image || !stage) {
			return
		}

		await wait(100)

		const stageRect = {
			width: stage.width() / stage.scaleX(),
			height: stage.height() / stage.scaleY(),
		}

		const imageRect = image.getClientRect({ relativeTo: stage })

		if (position === "1:1") {
			// top left
			image.to({ x: 0, y: 0 })
		}

		if (position === "1:2") {
			// top center
			image.to({ x: stageRect.width / 2 - imageRect.width / 2, y: 0 })
		}

		if (position === "1:3") {
			// top right
			image.to({ x: stageRect.width - imageRect.width, y: 0 })
		}

		if (position === "2:1") {
			// center left
			image.to({ x: 0, y: stageRect.height / 2 - imageRect.height / 2 })
		}

		if (position === "2:2") {
			// center
			const x = (stageRect.width - imageRect.width) / 2
			const y = (stageRect.height - imageRect.height) / 2

			if (image.opacity() === 0) {
				// initially it is hidden
				image.x(x)
				image.y(y)
				image.opacity(1)
				return
			}

			image.to({ x, y })
		}

		if (position === "2:3") {
			// center right
			image.to({
				x: stageRect.width - imageRect.width,
				y: stageRect.height / 2 - imageRect.height / 2,
			})
		}

		if (position === "3:1") {
			// bottom left
			image.to({ x: 0, y: stageRect.height - imageRect.height })
		}

		if (position === "3:2") {
			// bottom center
			image.to({
				x: stageRect.width / 2 - imageRect.width / 2,
				y: stageRect.height - imageRect.height,
			})
		}

		if (position === "3:3") {
			// bottom right
			image.to({
				x: stageRect.width - imageRect.width,
				y: stageRect.height - imageRect.height,
			})
		}
	},
})

export const hapticImpactFx = domain.createEffect(async () => {
	await Haptics.impact({ style: ImpactStyle.Medium })
})

export const createFeedbackFx = domain.createEffect(async (interest: Interest) => {
	const requestBody = {
		vcardId:
			$currentVCardId.getState() !== 0
				? $currentVCardId.getState()
				: $justCreatedVCardId.getState(),
		topics: interest.topics,
		accountManager: interest.accountManager,
		additionalData: interest.additionalData,
		reconnectionPeriod: interest.reconnectionPeriod,
		reconnectionAmount: interest.reconnectionAmount,
	}

	return api.post("topic-interest", { json: requestBody })
})
export const updateFeedbackFx = domain.createEffect(async (interest: Interest) => {
	const requestBody = {
		vcardId:
			$currentVCardId.getState() !== 0
				? $currentVCardId.getState()
				: $justCreatedVCardId.getState(),
		topics: interest.topics,
		id: interest.id,
		accountManager: interest.accountManager,
		additionalData: interest.additionalData,
		reconnectionPeriod: interest.reconnectionPeriod,
		reconnectionAmount: interest.reconnectionAmount,
	}

	return api.patch("topic-interest", { json: requestBody })
})

// create new vCard by eventId
export const createNewVcardFx = domain.createEffect(async (contact: Contact) => {
	const currentImg = $preset.getState()
	const selectedImg = currentImg.url

	const isFromPresets = $presets.getState().some(preset => preset.url === selectedImg)

	const terms = []

	if (contact.acceptedProcessingAt) {
		terms.push("DATA_PROCESSING_AGREEMENT")
	}

	if (contact.acceptedPromotionsAt) {
		terms.push("EMAIL_NOTIFICATION")
	}
	let eventId = $currentEventId.getState()

	if (!eventId) {
		eventId = $events.getState()[0]?.events?.[0]?.id ?? 1
	}

	const requestBody = {
		vcard: {
			eventId,
			name: `${contact.firstName} ${contact.lastName}`,
			image: isFromPresets ? selectedImg : "",
			terms,
		},
		userData: {
			firstName: contact.firstName,
			lastName: contact.lastName,
			email: contact.email,
			phone: contact.phone,
			company: contact.company,
			position: contact.position,
			website: contact.website,
			country: contact.country,
			state: contact.state,
			address1: contact.address1,
			address2: contact.address2,
			city: contact.city,
			postalCode: contact.postalCode,
		},
	}
	showFeedBack(true)
	const response = await api.post("vcards/user", { json: requestBody })
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const responseData = (await response.json()) as Record<string, any>
	setJustCreatedVCardId(responseData.vcard.vcardId)
})

/// update vCard
export const updateVcardFx = domain.createEffect(async (contact: Contact) => {
	const currentImg = $preset.getState()
	const selectedImg = currentImg.url

	const isFromPresets = $presets.getState().some(preset => preset.url === selectedImg)
	const terms = []

	if (contact.acceptedProcessingAt) {
		terms.push("DATA_PROCESSING_AGREEMENT")
	}

	if (contact.acceptedPromotionsAt) {
		terms.push("EMAIL_NOTIFICATION")
	}

	const requestBody = {
		vcard: {
			eventId: $currentEventId.getState(),
			vcardId:
				$currentVCardId.getState() !== 0
					? $currentVCardId.getState()
					: $justCreatedVCardId.getState(),
			image: isFromPresets ? selectedImg : "",
			terms,
		},
		userData: {
			firstName: contact.firstName,
			lastName: contact.lastName,
			email: contact.email,
			phone: contact.phone,
			company: contact.company,
			position: contact.position,
			website: contact.website,
			country: contact.country,
			city: contact.city,
			state: contact.state,
			address1: contact.address1,
			address2: contact.address2,
			postalCode: contact.postalCode,
		},
	}
	return api.patch(`vcards/user`, { json: requestBody })
})
export const storeContactInSheetFx = attach({
	source: { token: $token },
	effect: async ({ token }, contact: Contact): Promise<void> => {
		const payload = {
			FIRST_NAME: contact.firstName,
			LAST_NAME: contact.lastName,
			EMAIL: contact.email,
			PHONE: contact.phone,
			POSITION: contact.position,
			COMPANY: contact.company,
			WEBSITE: contact.website,
			STATE: contact.state,
			ADDRESS1: contact.address1,
			ADDRESS2: contact.address2,
			POSTALCODE: contact.postalCode,
			CITY: contact.city,
			COUNTRY: contact.country,
			ACCEPTED_PROCESSING_AT: formatDatePayload(contact.acceptedProcessingAt),
			ACCEPTED_PROMOTIONS_AT: formatDatePayload(contact.acceptedPromotionsAt),
		}

		// TODO: implement network layer?
		const response = await fetch(config.sheetUrl + "/v1", {
			method: "POST",

			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify(payload),
		})

		assert(response.ok, "Failed to store contact in sheet")
	},
})
// TODO: can be done in one request with fetchUserDataFx need to change after demo
export const fetchFeedbackFx = domain.createEffect(async (vcardId: number) => {
	const response = await api.get(`vcards/user-terms-topic/${vcardId}`)

	assert(response.ok, `Failed to fetch events: ${response.status} ${response.statusText}`)

	try {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const userData = (await response.json()) as Record<string, any>

		// TODO: define a zod schema and parse the response
		const userDataById = {
			id: userData.interest.id,
			topics: userData.interest.topics,
			accountManager: userData.interest.accountManager,
			additionalData: userData.interest.additionalData,
			reconnectionPeriod: userData.interest.reconnectionPeriod,
			reconnectionAmount: userData.interest.reconnectionAmount,
		}
		return userDataById
	} catch (error) {
		throw new Error("Failed to parse response body")
	}
})
export const fetchUserDataFx = domain.createEffect(async (vcardId: number) => {
	const response = await api.get(`vcards/user-terms-topic/${vcardId}`)

	assert(response.ok, `Failed to fetch events: ${response.status} ${response.statusText}`)

	try {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		const userData = (await response.json()) as Record<string, any>
		const isCorrectURL = $presets.getState().some(preset => preset.url === userData.image)
		presetChanged({ url: isCorrectURL ? userData.image : '' })
		const newImage = new Image()
		newImage.src = userData.image
		newImage.width = config.canvas.width
		newImage.height = config.canvas.height
		updateBackgroundImage(isCorrectURL && userData.image !== '' ? newImage : null)


		// TODO: define a zod schema and parse the response
		const userDataById = {
			firstName: userData.userData.firstName,
			lastName: userData.userData.lastName,
			email: userData.userData.email,
			phone: userData.userData.phone,
			company: userData.userData.company,
			position: userData.userData.position,
			website: userData.userData.website,
			country: userData.userData.country,
			state: userData.userData.state,
			address1: userData.userData.address1,
			address2: userData.userData.address2,
			postalCode: userData.userData.postalCode,
			city: userData.userData.city,
			acceptedProcessingAt: userData.terms.includes("DATA_PROCESSING_AGREEMENT")
				? new Date().toISOString()
				: null,
			acceptedPromotionsAt: userData.terms.includes("EMAIL_NOTIFICATION")
				? new Date().toISOString()
				: null,
		}
		return userDataById
	} catch (error) {
		throw new Error("Failed to parse response body")
	}
})

export const scanCardFx = domain.createEffect(async () => {
	return await Scan.scan()
})

export const fillFormFromScanFx = attach({
	source: $formValues,
	effect: (formValues, scanResult: ScanResult): Contact => {
		const { content } = scanResult
		const values = { ...formValues }

		const name = content.find((x) => x.type === "name")?.value ?? ""
		const [firstName, lastName] = name.split(" ")

		if (!values.firstName && !values.lastName && firstName && lastName) {
			values.firstName = firstName
			values.lastName = lastName
		}

		if (!values.firstName) {
			values.firstName = content.find((x) => x.type === "name")?.value ?? values.firstName
		}

		if (!values.email) {
			values.email = content.find((x) => x.type === "email")?.value ?? values.email
		}

		if (!values.phone) {
			values.phone = content.find((x) => x.type === "phone")?.value ?? values.phone
		}

		if (!values.website) {
			values.website = content.find((x) => x.type === "website")?.value ?? values.website
		}

		// TODO: fill the address fields when the form is ready

		return values
	},
})

// helpers

async function readStoredNewContacts(): Promise<Contact[]> {
	const stored = await Preferences.get({ key: config.newContactsStoreKey })

	const raw: Array<Contact> = tryParseJson(stored.value, [])

	const map = new Map<string, Contact>()

	for (const item of raw) {
		const parseResult = await ContactSchema.safeParseAsync(item)

		if (!parseResult.success) {
			trackError("Failed to parse stored contact", parseResult.error)
			continue
		}

		map.set(item.email, parseResult.data)
	}

	return [...map.values()]
}

async function clearStoredNewContacts(): Promise<void> {
	await Preferences.remove({ key: config.newContactsStoreKey })
}

function formatDatePayload(date: string | null): string {
	if (!date) {
		return ""
	}

	return new Date(date).toLocaleString()
}

export const saveScannedPicturesFx = domain.createEffect(async (scanResult: ScanResult) => {
	const formData = new FormData()

	const url = scanResult.images[$scannedImages.getState().length > 0 ? $scannedImages.getState().length - 1 : 0]
	const fileName = url.substring(url.lastIndexOf('tmp/') + 4)
	const response = await fetch(url)
	const blob = await response.blob()

	const arrayBuffer = await new Promise<ArrayBuffer>((resolve) => {
	  const reader = new FileReader()
	  reader.onloadend = () => resolve(reader.result as ArrayBuffer)
	  reader.readAsArrayBuffer(blob)
	})

	const file = new File([arrayBuffer], fileName, { type: 'image/jpeg' })

	formData.append('file', file)

	return api.post(`file/upload`, { body: formData })
  })
