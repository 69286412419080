import { IonToggle } from "@ionic/react"

import { DateIso, DateIsoUtcSchema } from "../domain/editor"
import classes from "./form-acceptance.module.css"

type Props = {
	children: string
	value: DateIso | null
	onChange: (value: DateIso | null) => void
}

export function FormAcceptance(props: Props) {
	const { value, onChange, children } = props

	const onClick = () => {
		const newValue = value === null ? DateIsoUtcSchema.parse(new Date().toISOString()) : null
		onChange(newValue)
	}

	return (
		<div className={classes.root} onClick={onClick}>
			<div className={classes.label}>{children}</div>

			<IonToggle className={classes.control} checked={value !== null} aria-label={children} />
		</div>
	)
}
