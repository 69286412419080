import { sample } from "effector"

import { AppGate } from "../app"
import {
	$isConnected,
	isOnlineChanged,
	listenNetworkStatusFx,
	stopListenNetworkStatusFx,
} from "./network-units"

$isConnected.on(isOnlineChanged, (_, isConnected) => isConnected)

sample({
	clock: AppGate.open,
	target: listenNetworkStatusFx,
})

sample({
	clock: AppGate.close,
	target: stopListenNetworkStatusFx,
})
