import { IonButton } from "@ionic/react"
import clsx from "clsx"

import classes from "./expand-button.module.css"

type Props = {
	expanded?: boolean
	onClick?: () => void
}

export function ExpandButton(props: Props) {
	const { expanded, onClick } = props

	return (
		<IonButton
			className={clsx(classes.button, expanded && classes.buttonExpanded)}
			onClick={onClick}
			fill="clear"
		>
			<svg xmlns="http://www.w3.org/2000/svg" width={20} height={38} fill="none">
				<g filter="url(#a)">
					<rect
						width={18}
						height={36}
						x={19}
						y={37}
						fill="#fff"
						rx={4}
						shapeRendering="crispEdges"
						transform="rotate(-180 19 37)"
					/>
					<path
						stroke="#000"
						strokeLinecap="round"
						strokeWidth={1.3}
						d="m12.5 24-5-5 5-5"
					/>
				</g>
				<defs>
					<filter
						id="a"
						width={20}
						height={38}
						x={0}
						y={0}
						colorInterpolationFilters="sRGB"
						filterUnits="userSpaceOnUse"
					>
						<feFlood floodOpacity={0} result="BackgroundImageFix" />
						<feColorMatrix
							in="SourceAlpha"
							result="hardAlpha"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
						/>
						<feOffset />
						<feGaussianBlur stdDeviation={0.5} />
						<feComposite in2="hardAlpha" operator="out" />
						<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0" />
						<feBlend in2="BackgroundImageFix" result="effect1_dropShadow_583_3867" />
						<feBlend
							in="SourceGraphic"
							in2="effect1_dropShadow_583_3867"
							result="shape"
						/>
					</filter>
				</defs>
			</svg>
		</IonButton>
	)
}
