export function ScanIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={25} height={24} fill="none">
			<path
				fill="var(--ion-color-primary)"
				fillRule="evenodd"
				d="M10.06 2a3 3 0 0 0-2.91 2.28l-.31 1.25a1 1 0 0 1-.775.74h-.001l-2.149.43A3 3 0 0 0 1.5 9.688v9.351a3 3 0 0 0 3 3h16a3 3 0 0 0 3-3V9.689a3 3 0 0 0-2.414-2.99l-2.15-.43a1 1 0 0 1-.775-.74l-.31-1.249A3 3 0 0 0 14.94 2h-4.88Zm-.615 2.213A1 1 0 0 1 10.061 4h4.879a1 1 0 0 1 .97.76l.31 1.25a3 3 0 0 0 2.325 2.22l2.15.43a1 1 0 0 1 .806 1V19.04a1 1 0 0 1-1 1h-16a1 1 0 0 1-1-1V9.66a1 1 0 0 1 .804-1h.001l2.15-.43a3 3 0 0 0 2.325-2.22l.31-1.25a1 1 0 0 1 .354-.547ZM9.5 13a3 3 0 1 1 6 0 3 3 0 0 1-6 0Zm3-5a5 5 0 1 0 0 10 5 5 0 0 0 0-10Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}
