import { wait } from "./wait"

type Options = {
	timeout?: number
	interval?: number
}

export async function waitFor<T>(fn: () => T | undefined, options: Options = {}): Promise<T> {
	const { interval = 10, timeout = 5000 } = options

	const start = performance.now()

	// eslint-disable-next-line no-constant-condition
	while (true) {
		const now = performance.now()

		if (now - start > timeout) {
			throw new Error("Timeout waiting for condition")
		}

		const result = fn()

		if (result !== undefined) {
			return result
		}

		await wait(interval)
	}
}
