export const SignOutIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.9382 1C10.6121 1 9.34038 1.52679 8.40271 2.46446C7.46506 3.40212 6.93819 4.67388 6.93819 6V8C6.93819 9.32612 7.46506 10.5979 8.40271 11.5355C9.34038 12.4732 10.6121 13 11.9382 13C13.2643 13 14.536 12.4732 15.4737 11.5355C16.4113 10.5979 16.9382 9.32612 16.9382 8V6C16.9382 4.67388 16.4113 3.40212 15.4737 2.46446C14.536 1.52679 13.2643 1 11.9382 1ZM9.81693 3.87868C10.3795 3.31606 11.1426 3 11.9382 3C12.7338 3 13.4968 3.31606 14.0595 3.87868C14.6221 4.44131 14.9382 5.20439 14.9382 6V8C14.9382 8.79561 14.6221 9.55869 14.0595 10.1213C13.4968 10.6839 12.7338 11 11.9382 11C11.1426 11 10.3795 10.6839 9.81693 10.1213C9.25429 9.55869 8.93819 8.79561 8.93819 8V6C8.93819 5.20439 9.25429 4.44131 9.81693 3.87868ZM3.87637 22.3461C5.28719 18.5221 8.45166 16 11.9382 16V14C7.42471 14 3.62919 17.2379 2 21.6539L3.87637 22.3461ZM17.9382 20H12.9382V18H17.9382V15L22.9382 19L17.9382 23V20Z"
			fill="black"
		/>
	</svg>
)
