import React from "react"
import { IonToggle } from "@ionic/react"

import classes from "./switch.module.css"

type Props = {
	checked?: boolean
	disabled?: boolean
	onChange?: (checked: boolean) => void
	children?: React.ReactNode
}

export function Switch(props: Props) {
	const { checked, disabled, onChange, children } = props

	return (
		<IonToggle
			className={classes.toggle}
			justify="space-between"
			checked={checked}
			disabled={disabled}
			onIonChange={(event) => onChange?.(event.detail.checked)}
		>
			{children}
		</IonToggle>
	)
}
