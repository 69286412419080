import { useIntl } from "react-intl"
import { IonButton, IonIcon } from "@ionic/react"
import { mail } from "ionicons/icons"
import { useUnit } from "effector-react"

import { config } from "../config"
import {
	$currentEventId,
	$isFormValid,
	clearBackgroundImage,
	newVcardOnFormPressed,
	presetChanged,
	setJustCreatedVCardId,
	shareViaEmailClicked,
	showFeedBack,
	vCardId,
} from "../store/editor"
import { $isUpdate, isUpdateAvailable, updateOnShare } from "../store/events"
import { shareButtonDisplay } from "../store/events/events-units"
import classes from "./new-vcard-button.module.css"
import { PlusIcon } from "./plus-icon"
import UpdateIcon from "./update-icon"

export function NewVcardButton() {
	const intl = useIntl()
	const { isFormValid, isUpdate, currentEventId } = useUnit({
		isFormValid: $isFormValid,
		isUpdate: $isUpdate,
		currentEventId: $currentEventId
	})

	const createNewVcard = () => {
		clearBackgroundImage(null)
		presetChanged({ url: "" })
		shareButtonDisplay(true)
		vCardId(0)
		showFeedBack(false)
		newVcardOnFormPressed()
		updateOnShare(true)
		setJustCreatedVCardId(0)
	}

	return (
		<div className={classes.buttonBox}>
			{config.isNativeApp && (
				<IonButton disabled={!isFormValid} onClick={() => shareViaEmailClicked()}>
					<IonIcon icon={mail} />
				</IonButton>
			)}

			<div className={classes.buttonsBox}>
				<IonButton
					className={classes.buttonBoxTitle}
					disabled={isUpdate || !currentEventId}
					onClick={() => isUpdateAvailable()}
				>
					<UpdateIcon />
					<span className={classes.updateCard}>
						{intl.formatMessage({
							id: "update-vcard",
							defaultMessage: "Update",
						})}
					</span>
				</IonButton>
			</div>

			<div className={classes.buttonsBox} onClick={() => createNewVcard()}>
				<IonButton className={classes.buttonBoxTitle} disabled={!currentEventId} fill="clear">
					<div className={classes.newCard}>
						<PlusIcon />
						<p className={classes.newCardTitle}>
							{intl.formatMessage({
								id: "create-new-vcard-from-outside-form",
								defaultMessage: "New vCard",
							})}
						</p>
					</div>
				</IonButton>
			</div>
		</div>
	)
}
