import { useState } from "react"
import { useIntl } from "react-intl"
import { useHistory, useRouteMatch } from "react-router-dom"
import {
	IonButton,
	IonButtons,
	IonContent,
	IonHeader,
	IonModal,
	IonPage,
	IonSpinner,
	IonTitle,
	IonToolbar,
} from "@ionic/react"
import { useGate, useUnit } from "effector-react"
import clsx from "clsx"

import LeftChevronIcon from "../events/left-chevron"
import { ExpandButton } from "../expand-button/expand-button"
import {
	$currentVCardId,
	$isFormExpanded,
	EditorScreenGate,
	fetchUserDataFx,
	isFormExpandedChanged,
} from "../store/editor"
import { $fieldUpdate, $isUpdate } from "../store/events"
import modalClasses from "./../login/sign-out-button.module.css"
import { ControlsSection } from "./controls-section"
import classes from "./editor-screen.module.css"
import { FormSection } from "./form-section"
import { NewVcardButton } from "./new-vcard-button"
import { PreviewSection } from "./preview-section"

export function EditorScreen() {
	const intl = useIntl()
	const [saveAlert, setSaveAlert] = useState(false)
	const history = useHistory()
	const match = useRouteMatch<{ id: string }>()
	useGate(EditorScreenGate, { vCardId: match.params.id ?? null })

	const { isFormSectionExpanded, isUpdate, isFieldUpdated, currentVCardId, isPending } = useUnit({
		isFormSectionExpanded: $isFormExpanded,
		isUpdate: $isUpdate,
		isFieldUpdated: $fieldUpdate,
		currentVCardId: $currentVCardId,
		isPending: fetchUserDataFx.pending
	})

	const handleFn = () => {
		if (isFieldUpdated && currentVCardId !== 0) {
			setSaveAlert(true)
		} else {
			history.goBack()
		}
	}

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonButtons slot="start" onClick={handleFn}>
						<LeftChevronIcon />
						<span className={classes.back}>
							{intl.formatMessage({
								id: "go-back",
								defaultMessage: "Back",
							})}
						</span>
					</IonButtons>

					<IonTitle>
						{intl.formatMessage({
							id: "editor-page-title",
							defaultMessage: "Quantori QR Code",
						})}
					</IonTitle>
					<IonButtons slot="end">
						<NewVcardButton />
					</IonButtons>
				</IonToolbar>
			</IonHeader>

			<IonContent className={classes.content}>
				<div className={classes.sections}>
					{!isFormSectionExpanded && isUpdate && (
						<div className={classes.section}>
							<ControlsSection />
						</div>
					)}

					<div className={classes.section}>
						{isPending ? <div className={classes.spinner}><IonSpinner /></div> : <PreviewSection />}
					</div>

					<div className={clsx(classes.section, classes.sectionForm)}>
						<FormSection />

						<div className={classes.expandButton}>
							<ExpandButton
								expanded={isFormSectionExpanded}
								onClick={() => isFormExpandedChanged(!isFormSectionExpanded)}
							/>
						</div>
					</div>
				</div>
			</IonContent>

			<IonModal
				isOpen={saveAlert}
				className={modalClasses.signOutModal}
				onDidDismiss={() => setSaveAlert(false)}
			>
				<div className={modalClasses.modalWrapper}>
					<p className={modalClasses.saveModalTitle}>
						{intl.formatMessage({
							id: "do-you-want-leave-without-changes",
							defaultMessage: "Are you sure you want to leave without changes?",
						})}
					</p>
					<div className={modalClasses.modalsButtons}>
						<div
							className={clsx(
								modalClasses.modalButtonWrapper,
								modalClasses.modalButtonFirst
							)}
						>
							<IonButton
								className={modalClasses.modalButton}
								fill="clear"
								onClick={() => setSaveAlert(false)}
							>
								{intl.formatMessage({
									id: "cancel",
									defaultMessage: "Cancel",
								})}
							</IonButton>
						</div>
						<div className={modalClasses.modalButtonWrapper}>
							<IonButton
								className={modalClasses.modalButton}
								fill="clear"
								onClick={() => {
									setSaveAlert(false)
									history.goBack()
								}}
							>
								{intl.formatMessage({
									id: "ok",
									defaultMessage: "OK",
								})}
							</IonButton>
						</div>
					</div>
				</div>
			</IonModal>
		</IonPage>
	)
}
