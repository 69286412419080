// Core CSS required for Ionic components to work properly
import "@ionic/react/css/core.css"
// Basic CSS for apps built with Ionic
import "@ionic/react/css/normalize.css"
import "@ionic/react/css/structure.css"
import "@ionic/react/css/typography.css"
// Optional CSS utils that can be commented out
import "@ionic/react/css/padding.css"
import "@ionic/react/css/float-elements.css"
import "@ionic/react/css/text-alignment.css"
import "@ionic/react/css/text-transformation.css"
import "@ionic/react/css/flex-utils.css"
import "@ionic/react/css/display.css"
// Theme
import "../theme/variables.css"
import "../theme/global.css"
// Store initialization
import "../store/init"

import { useEffect, useRef } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { RawIntlProvider } from "react-intl"
import { IonApp, setupIonicReact, useIonLoading } from "@ionic/react"
import { useGate, useUnit } from "effector-react"

import { intl } from "../i18n"
import { trackError } from "../lib/track-error"
import { LoginPage } from "../login"
import { AppGate } from "../store/app"
import { $isAuthenticated, $isReady } from "../store/auth"
import classes from "./app.module.css"
import { AppRoutes } from "./app-routes"
import { ErrorBoundaryFallback } from "./error-boundary-fallback"

setupIonicReact({ mode: "ios" })

export function App() {
	useGate(AppGate)

	const { isAuthenticated, isReady } = useUnit({
		isAuthenticated: $isAuthenticated,
		isReady: $isReady,
	})

	const [present, dismiss] = useIonLoading()
	const flagRef = useRef(false)

	useEffect(() => {
		if (!isReady && !flagRef.current) {
			flagRef.current = true
			present({ message: "Loading..." })
		}

		if (isReady) {
			// dismiss with a delay to avoid flickering
			setTimeout(() => dismiss(), 1000)
		}
	}, [dismiss, isReady, present])

	const content = (() => {
		if (!isReady) {
			return null
		}

		if (isAuthenticated) {
			return <AppRoutes />
		}

		return <LoginPage />
	})()

	return (
		<RawIntlProvider value={intl}>
			<IonApp className={classes.app}>
				<ErrorBoundary
					FallbackComponent={ErrorBoundaryFallback}
					onError={(error, info) => trackError("ErrorBoundary", error, info)}
					onReset={reloadApp}
				>
					{content}
				</ErrorBoundary>
			</IonApp>
		</RawIntlProvider>
	)
}

function reloadApp() {
	window.location.href = "/"
	window.location.reload()
}
