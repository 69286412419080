export const UnlockIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M18 9.24316H6C4.89543 9.24316 4 10.1386 4 11.2432V20.2432C4 21.3477 4.89543 22.2432 6 22.2432H18C19.1046 22.2432 20 21.3477 20 20.2432V11.2432C20 10.1386 19.1046 9.24316 18 9.24316Z"
			stroke="#54687D"
			strokeWidth="2"
			strokeMiterlimit="10"
		/>
		<path d="M12 12.7532V18.7532" stroke="#54687D" strokeWidth="2" strokeMiterlimit="10" />
		<path
			d="M10.3398 4.59314L11.7598 3.1731C12.1313 2.80119 12.5725 2.50611 13.0581 2.30481C13.5437 2.10351 14.0642 2 14.5898 2C15.1155 2 15.636 2.10351 16.1216 2.30481C16.6072 2.50611 17.0484 2.80119 17.4199 3.1731C17.7918 3.54459 18.0868 3.98572 18.2881 4.47131C18.4894 4.95691 18.593 5.47739 18.593 6.00305C18.593 6.52871 18.4894 7.0492 18.2881 7.53479C18.0868 8.02038 17.7918 8.46164 17.4199 8.83313L17 9.25305"
			stroke="#54687D"
			strokeWidth="2"
			strokeMiterlimit="10"
		/>
	</svg>
)
