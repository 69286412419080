import { Redirect, Route } from "react-router"
import { IonRouterOutlet } from "@ionic/react"
import { IonReactRouter } from "@ionic/react-router"

import { EditorScreen } from "../editor"
import { EventsScreen } from "../events"
import { ScreensRouts } from "./screens"

export function AppRoutes() {
	return (
		<IonReactRouter>
			<IonRouterOutlet>
				<Route path={ScreensRouts.editor}>
					<EditorScreen />
				</Route>

				<Route exact path={ScreensRouts.events}>
					<EventsScreen />
				</Route>

				<Route>
					<Redirect to={ScreensRouts.events} />
				</Route>
			</IonRouterOutlet>
		</IonReactRouter>
	)
}
