import { Configuration, PublicClientApplication } from "@azure/msal-browser"

import { AuthPlugin } from "../../auth"
import { config } from "../../config"

export class AuthWeb implements AuthPlugin {
	client: PublicClientApplication

	constructor(configuration: Configuration = config.msal) {
		this.client = new PublicClientApplication(configuration)
	}

	async acquireTokenSilently() {
		const account = this.client.getAllAccounts()?.[0]

		const result = await this.client.acquireTokenSilent({ scopes: ["user.read"], account })
		return { token: result.idToken }
	}

	async acquireTokenInteractively() {
		const result = await this.client.loginPopup({ scopes: ["user.read"] })

		return { token: result.idToken }
	}

	signOut() {
		const logoutHint = this.client.getAllAccounts()?.[0]?.idTokenClaims?.login_hint
		this.client.logoutPopup({ logoutHint })

		window.location.href = '/auth'
	}
}
