import Konva from "konva"

import { config } from "../../config"

export function isNearCenter(stage: Konva.Stage, image: Konva.Image): boolean {
	const qrCodeCenter = {
		x: image.x() + (image.width() * image.scaleX()) / 2,
		y: image.y() + (image.height() * image.scaleY()) / 2,
	}

	const delta = {
		x: stage.width() / 2 - qrCodeCenter.x,
		y: stage.height() / 2 - qrCodeCenter.y,
	}

	const distance = Math.sqrt(delta.x * delta.x + delta.y * delta.y)

	return distance <= config.snapToCenterRadius
}
