import { useIntl } from "react-intl"
import { InputInputEventDetail, IonInputCustomEvent } from "@ionic/core"
import { IonButton, IonSpinner } from "@ionic/react"
import { useKeyboardState } from "@ionic/react-hooks/keyboard"
import { useUnit } from "effector-react"

import { config } from "../config"
import { ContactForm } from "../contact-form"
import {
	$feedbackData,
	$formValues,
	$formValuesSuggestions,
	$isFormValid,
	clearOldFeedback,
	formValuesChanged,
	scanCardClicked,
	scanCardFx,
	shareClicked,
} from "../store/editor"
import { $isUpdate, isFieldUpdated } from "../store/events"
import { ContactBookIcon } from "./contact-book-icon"
import { FeedbackModal } from "./feedback-modal"
import { FormAcceptance } from "./form-acceptance"
import classes from "./form-section.module.css"
import { ScanIcon } from "./scan-icon"

export function FormSection() {
	const intl = useIntl()
	const { isOpen } = useKeyboardState()

	const { values, isFormValid, feedbackData, isScanning, suggestions, isUpdate } = useUnit({
		isFormValid: $isFormValid,
		values: $formValues,
		feedbackData: $feedbackData,
		isScanning: scanCardFx.pending,
		suggestions: $formValuesSuggestions,
		isUpdate: $isUpdate
	})

	const shareClickedBtn = () => {
		shareClicked()
		if (feedbackData.id === 0) {
			clearOldFeedback()
		}
	}

	return (
		<div className={classes.root}>
			<div className={classes.toolbar}>
				<div>
					<IonButton fill="clear">
						<div className={classes.toolbarButton}>
							{intl.formatMessage({
								id: "editor-toolbar-contact-book",
								defaultMessage: "Contact book",
							})}

							<ContactBookIcon />
						</div>
					</IonButton>

					{config.isNativeApp && (
						<IonButton
							fill="clear"
							onClick={() => scanCardClicked()}
							disabled={isScanning || !isUpdate}
						>
							<div className={classes.toolbarButton}>
								{intl.formatMessage({
									id: "editor-toolbar-scan",
									defaultMessage: "Scan",
								})}

								{isScanning && <IonSpinner />}

								{!isScanning && <ScanIcon />}
							</div>
						</IonButton>
					)}
				</div>
			</div>

			<div className={classes.formWrapper} style={isOpen ? { overflow: 'auto' } : undefined}>
				<ContactForm values={values} onFieldChange={onValueChange} suggestion={suggestions} />

				<div className={classes.footer}>
					<FormAcceptance
						value={values.acceptedProcessingAt}
						onChange={(value) => formValuesChanged({ acceptedProcessingAt: value })}
					>
						{intl.formatMessage({
							id: "editor-acceptance-promotions",
							defaultMessage: "I agree to the personal data processing",
						})}
					</FormAcceptance>

					<FormAcceptance
						value={values.acceptedPromotionsAt}
						onChange={(value) => formValuesChanged({ acceptedPromotionsAt: value })}
					>
						{intl.formatMessage({
							id: "editor-acceptance-promotions",
							defaultMessage: "I want to receive  notifications from Quantori",
						})}
					</FormAcceptance>

					{!isUpdate && (
						<IonButton
							shape="round"
							onClick={() => shareClickedBtn()}
							disabled={!isFormValid}
							expand="full"
						>
							{intl.formatMessage({
								id: "editor-form-share",
								defaultMessage: "Share vCard",
							})}
						</IonButton>
					)}

					{isUpdate && <FeedbackModal />}
				</div>
			</div>
		</div>
	)
}

function onValueChange(event: IonInputCustomEvent<InputInputEventDetail>) {
	const { name, value } = event.target as HTMLIonInputElement

	isFieldUpdated(true)

	formValuesChanged({ [name]: value })
}
