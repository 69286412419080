import { attach } from "effector"
import { some } from "patronum"

import { Auth } from "../../auth"
import { config } from "../../config"
import { appDomain } from "../app"
import { AuthWeb } from "./auth-web"

const domain = appDomain.createDomain("auth")

export const signIn = domain.createEvent()
export const signOut = domain.createEvent()

export const $isReady = domain.createStore(false)

export const $auth = domain.createStore(config.isWeb ? new AuthWeb() : Auth)

export const $token = domain.createStore<string | null>(null)
export const $isAuthenticated = $token.map((token) => token !== null)

export const restoreFx = attach({
	source: $auth,
	effect: async (auth) => await auth.acquireTokenSilently(),
})

export const signInFx = attach({
	source: $auth,
	effect: async (auth) => await auth.acquireTokenInteractively(),
})

export const signOutFx = attach({
	source: $auth,
	effect: async (auth) => auth.signOut(),
})

export const $isPending = some([restoreFx.pending, signInFx.pending, signOutFx.pending], (x) => x)
