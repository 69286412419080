import { useIntl } from "react-intl"
import { useUnit } from "effector-react"

import {
	$bgAlignment,
	$qrCodeAlignment,
	bgAlignmentChanged,
	qrCodeAlignmentChanged,
} from "../store/editor"
import { AlignmentControl } from "./alignment-control"
import classes from "./alignment-controls.module.css"

export function AlignmentControls() {
	const intl = useIntl()

	const { qr, bg } = useUnit({
		qr: $qrCodeAlignment,
		bg: $bgAlignment,
	})

	return (
		<div>
			<h2 className={classes.heading}>
				{intl.formatMessage({
					id: "editor-alignment-title",
					defaultMessage: "Alignment",
				})}
			</h2>

			<div className={classes.controls}>
				<div>
					<h3 className={classes.label}>
						{intl.formatMessage({
							id: "editor-alignment-background",
							defaultMessage: "Background",
						})}
					</h3>

					<AlignmentControl value={bg} onChange={(pos) => bgAlignmentChanged(pos)} />
				</div>

				<div>
					<h3 className={classes.label}>
						{intl.formatMessage({
							id: "editor-alignment-qr-code",
							defaultMessage: "QR code",
						})}
					</h3>

					<AlignmentControl value={qr} onChange={(pos) => qrCodeAlignmentChanged(pos)} />
				</div>
			</div>
		</div>
	)
}
