import { combine, createDomain, StoreValue } from "effector"
import { createGate } from "effector-react"

export const appDomain = createDomain("app")

export const AppGate = createGate({ domain: appDomain })

export const $windowSize = appDomain.createStore({
	width: window.innerWidth,
	height: window.innerHeight,
})

export const $cssVariables = combine($windowSize, (size) => {
	const { width, height } = size

	return {
		"window-width": `${width}px`,
		"window-height": `${height}px`,
	}
})

export const setCssVariablesFx = appDomain.createEffect(
	(variables: StoreValue<typeof $cssVariables>) => {
		const body = document.body

		Object.entries(variables).forEach(([key, value]) => {
			body.style.setProperty(`--${key}`, value)
		})
	}
)
