import ky, { AfterResponseHook, BeforeRequestHook } from "ky"

import { config } from "../config"
import { $token, restoreFx, signOut } from "../store/auth"
import { showErrorToastFx } from "../store/toaster"

let counter = 0

const setTokenHeaderHook: BeforeRequestHook = (request) => {
	// eslint-disable-next-line effector/no-getState
	const token = $token.getState()
	request.headers.set("Authorization", `Bearer ${token}`)
}

const handle403: AfterResponseHook = async (_request, _options, response) => {
	if (response.status === 403) {
		signOut()
		await showErrorToastFx("You are not authorized")
	}
}

const handle401: AfterResponseHook = async (request, _options, response) => {
	if (response.status === 401) {
		counter++
		try {
			if (counter > 2) {
				throw new Error("")
			}
			const { token } = await restoreFx()
			request.headers.set("Authorization", `Bearer ${token}`)

			return api(request)
		} catch (error) {
			signOut()

			const message = error instanceof Error ? error.message : "You are not authorized"
			showErrorToastFx(message)
		}
	}
}

export const api = ky.create({
	prefixUrl: config.apiUrl,
	retry: 0,
	hooks: {
		beforeRequest: [setTokenHeaderHook],
		beforeRetry: [],
		afterResponse: [handle401, handle403],
	},
})
