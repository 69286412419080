import { Toast } from "@capacitor/toast"

import { appDomain } from "../app"

const domain = appDomain.createDomain("toaster")

export const showErrorToastFx = domain.createEffect(async (messageOrError: string | Error) => {
	const text = typeof messageOrError === "string" ? messageOrError : messageOrError.message

	return await Toast.show({ text, duration: "long" })
})
