export const ScreensRouts = {
	editor: "/editor/:id?",
	events: "/",
	auth: "/auth",
}

export const screensUrlFactory = {
	events() {
		return "/"
	},

	editor(vCardId?: number) {
		if (vCardId !== undefined) {
			return `/editor/${vCardId}`
		}

		return "/editor"
	},

	auth() {
		return "/auth"
	},
}
