export const ShareIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width={23} height={19} fill="none">
		<path
			stroke="#54687D"
			strokeLinejoin="round"
			strokeWidth={2}
			d="M1 18c0-5 6.53-6 11-6v5l8.63-7.22a1 1 0 0 0 0-1.56L12 1v5C4 6 1 11.64 1 18Z"
		/>
	</svg>
)
