import { registerPlugin } from "@capacitor/core"

type ScanContentType = "name" | "email" | "address" | "phone" | "website" | "other"
type ImageURL = string

export type ScanResult = {
	content: Array<{ type: ScanContentType; value: string }>
	images: Array<ImageURL>
}

export interface ScanPlugin {
	scan(): Promise<ScanResult>
}

export const Scan = registerPlugin<ScanPlugin>("Scan")
