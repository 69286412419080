export const FilterIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M23 12H1" stroke="#54687D" strokeWidth="2" strokeMiterlimit="10" />
		<path d="M23 19H1" stroke="#54687D" strokeWidth="2" strokeMiterlimit="10" />
		<path d="M23 5H1" stroke="#54687D" strokeWidth="2" strokeMiterlimit="10" />
		<path d="M10 2V8" stroke="#54687D" strokeWidth="2" strokeMiterlimit="10" />
		<path d="M18 9V15" stroke="#54687D" strokeWidth="2" strokeMiterlimit="10" />
		<path d="M6 16V22" stroke="#54687D" strokeWidth="2" strokeMiterlimit="10" />
	</svg>
)
