import { IonButton, IonIcon, useIonActionSheet } from "@ionic/react"
import { listOutline } from "ionicons/icons"

import { Contact } from "../domain/editor"
import { formValuesChanged } from "../store/editor"

type Props = {
	list?: Array<string>
	field: keyof Contact
}

export const FormSuggestions = (props: Props) => {
	const { list = [], field } = props

	const [present] = useIonActionSheet()

	const onClick = () => {
		present({
			buttons: list.map((item) => {
				return {
					text: item,
					handler: () => {
						formValuesChanged({ [field]: item })
					},
				}
			}),
		})
	}

	if (list.length === 0) {
		return null
	}

	return (
		<IonButton onClick={onClick} fill="clear">
			<IonIcon icon={listOutline} />
		</IonButton>
	)
}
