import React from "react"
import { useIntl } from "react-intl"
import { InputChangeEventDetail, IonInputCustomEvent } from "@ionic/core"
import { IonInput, IonItem, IonList } from "@ionic/react"
import { useKeyboardState } from "@ionic/react-hooks/keyboard"
import { useUnit } from "effector-react"

import { Contact } from "../domain/editor"
import { $isUpdate } from "../store/events"
import classes from "./contact-form.module.css"
import { FormSuggestions } from "./form-suggestions"

type OnFieldChange = (event: IonInputCustomEvent<InputChangeEventDetail>) => void

type Props = {
	values: Contact
	onFieldChange: OnFieldChange
	suggestion?: Array<string>
}

export const ContactForm: React.FC<Props> = ({ values, onFieldChange, suggestion }) => {
	const intl = useIntl()
	const { isOpen } = useKeyboardState()

	const isUpdate = useUnit($isUpdate)

	return (
		<IonList className={classes.fields} style={isOpen ? undefined : { overflow: 'auto' }}>
			<IonItem lines="none" className={classes.fieldContainer}>
				<IonInput
					name="firstName"
					label={intl.formatMessage({
						id: "vcard-form-first-name",
						defaultMessage: "First name",
					})}
					labelPlacement="floating"
					onIonInput={onFieldChange}
					required
					value={values.firstName}
					disabled={!isUpdate}
					className={classes.fieldCards}
				/>

				<FormSuggestions list={suggestion} field="firstName" />
			</IonItem>

			<IonItem lines="none" className={classes.fieldContainer}>
				<IonInput
					name="lastName"
					label={intl.formatMessage({
						id: "vcard-form-last-name",
						defaultMessage: "Last name (suffix)",
					})}
					labelPlacement="floating"
					onIonInput={onFieldChange}
					value={values.lastName}
					required
					disabled={!isUpdate}
					className={classes.fieldCards}
				/>

				<FormSuggestions list={suggestion} field="lastName" />
			</IonItem>

			<IonItem lines="none" className={classes.fieldContainer}>
				<IonInput
					name="email"
					type="email"
					label={intl.formatMessage({
						id: "vcard-form-email",
						defaultMessage: "Email",
					})}
					labelPlacement="floating"
					onIonInput={onFieldChange}
					value={values.email}
					required
					disabled={!isUpdate}
					className={classes.fieldCards}
				/>

				<FormSuggestions list={suggestion} field="email" />
			</IonItem>

			<IonItem lines="none" className={classes.fieldContainer}>
				<IonInput
					name="phone"
					type="tel"
					label={intl.formatMessage({
						id: "vcard-form-phone",
						defaultMessage: "Phone",
					})}
					labelPlacement="floating"
					value={values.phone}
					onIonInput={onFieldChange}
					disabled={!isUpdate}
					className={classes.fieldCards}
				/>

				<FormSuggestions list={suggestion} field="phone" />
			</IonItem>

			<IonItem lines="none" className={classes.fieldContainer}>
				<IonInput
					name="position"
					label={intl.formatMessage({
						id: "vcard-form-position",
						defaultMessage: "Position",
					})}
					labelPlacement="floating"
					value={values.position}
					onIonInput={onFieldChange}
					disabled={!isUpdate}
					className={classes.fieldCards}
				/>

				<FormSuggestions list={suggestion} field="position" />
			</IonItem>

			<IonItem lines="none" className={classes.fieldContainer}>
				<IonInput
					name="company"
					label={intl.formatMessage({
						id: "vcard-form-company",
						defaultMessage: "Company",
					})}
					labelPlacement="floating"
					value={values.company}
					onIonInput={onFieldChange}
					disabled={!isUpdate}
					className={classes.fieldCards}
				/>

				<FormSuggestions list={suggestion} field="company" />
			</IonItem>

			<IonItem lines="none" className={classes.fieldContainer}>
				<IonInput
					name="website"
					type="url"
					label={intl.formatMessage({
						id: "vcard-form-website",
						defaultMessage: "Website",
					})}
					labelPlacement="floating"
					onIonInput={onFieldChange}
					value={values.website}
					disabled={!isUpdate}
					className={classes.fieldCards}
				/>

				<FormSuggestions list={suggestion} field="website" />
			</IonItem>

			<IonItem lines="none" className={classes.fieldContainer}>
				<IonInput
					name="country"
					label={intl.formatMessage({
						id: "vcard-form-country",
						defaultMessage: "Country",
					})}
					labelPlacement="floating"
					onIonInput={onFieldChange}
					value={values.country}
					disabled={!isUpdate}
					className={classes.fieldCards}
				/>

				<FormSuggestions list={suggestion} field="country" />
			</IonItem>

			<IonItem lines="none" className={classes.fieldContainer}>
				<IonInput
					name="state"
					label={intl.formatMessage({
						id: "vcard-form-state",
						defaultMessage: "State",
					})}
					labelPlacement="floating"
					onIonInput={onFieldChange}
					value={values.state}
					disabled={!isUpdate}
					className={classes.fieldCards}
				/>

				<FormSuggestions list={suggestion} field="state" />
			</IonItem>

			<IonItem lines="none" className={classes.fieldContainer}>
				<IonInput
					name="city"
					label={intl.formatMessage({
						id: "vcard-form-city",
						defaultMessage: "City",
					})}
					labelPlacement="floating"
					onIonInput={onFieldChange}
					value={values.city}
					disabled={!isUpdate}
					className={classes.fieldCards}
				/>

				<FormSuggestions list={suggestion} field="city" />
			</IonItem>

			<IonItem lines="none" className={classes.fieldContainer}>
				<IonInput
					name="address1"
					label={intl.formatMessage({
						id: "vcard-form-address1",
						defaultMessage: "Address1",
					})}
					labelPlacement="floating"
					onIonInput={onFieldChange}
					value={values.address1}
					disabled={!isUpdate}
					className={classes.fieldCards}
				/>

				<FormSuggestions list={suggestion} field="address1" />
			</IonItem>

			<IonItem lines="none" className={classes.fieldContainer}>
				<IonInput
					name="address2"
					label={intl.formatMessage({
						id: "vcard-form-address2",
						defaultMessage: "Address2",
					})}
					labelPlacement="floating"
					onIonInput={onFieldChange}
					value={values.address2}
					disabled={!isUpdate}
					className={classes.fieldCards}
				/>

				<FormSuggestions list={suggestion} field="address2" />
			</IonItem>

			<IonItem lines="none" className={classes.fieldContainer}>
				<IonInput
					name="postalCode"
					label={intl.formatMessage({
						id: "vcard-form-postal-code",
						defaultMessage: "Postal/Zip Code",
					})}
					labelPlacement="floating"
					onIonInput={onFieldChange}
					value={values.postalCode}
					disabled={!isUpdate}
					className={classes.fieldCards}
				/>

				<FormSuggestions list={suggestion} field="postalCode" />
			</IonItem>
		</IonList>
	)
}
