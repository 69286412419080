import { useIntl } from "react-intl"
import { IonButton, IonSpinner } from "@ionic/react"
import { useUnit } from "effector-react"

import { $isPending, signIn } from "../store/auth"
import classes from "./login-page.module.css"
import { QrCodeLogo } from "./qr-logo"

export const LoginPage = () => {
	const intl = useIntl()

	const isPending = useUnit($isPending)

	return (
		<div className={classes.loginPage}>
			<QrCodeLogo />

			<IonButton
				fill="clear"
				className={classes.loginPageButton}
				onClick={() => signIn()}
				disabled={isPending}
			>
				{isPending && <IonSpinner />}

				{!isPending && (
					<span className="ion-padding">
						{intl.formatMessage({ id: "sign-in-button", defaultMessage: "Sign In" })}
					</span>
				)}
			</IonButton>
		</div>
	)
}
