export const GarbageIcon = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M5 7H19V20C19 20.5304 18.7891 21.0391 18.4141 21.4142C18.039 21.7893 17.5304 22 17 22H7C6.46957 22 5.96077 21.7893 5.58569 21.4142C5.21062 21.0391 5 20.5304 5 20V7Z"
			stroke="#54687D"
			strokeWidth="2"
			strokeMiterlimit="10"
		/>
		<path d="M10 19V11" stroke="#54687D" strokeWidth="2" />
		<path d="M14 19V11" stroke="#54687D" strokeWidth="2" />
		<path
			d="M2 7H5.6499C6.0476 6.99919 6.43609 6.87988 6.76562 6.65723C7.09516 6.43457 7.35086 6.11868 7.5 5.75L8.5 3.26001C8.64757 2.88946 8.90252 2.57145 9.23218 2.34692C9.56184 2.1224 9.951 2.00161 10.3499 2H13.6499C14.0488 2.00161 14.4379 2.1224 14.7676 2.34692C15.0972 2.57145 15.3524 2.88946 15.5 3.26001L16.5 5.75C16.6498 6.12026 16.9068 6.43725 17.2383 6.66003C17.5698 6.88282 17.9605 7.00119 18.3599 7H22"
			stroke="#54687D"
			strokeWidth="2"
			strokeMiterlimit="10"
		/>
	</svg>
)
