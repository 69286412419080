/* eslint-disable effector/no-getState */
import { sample } from "effector"

import { $currentEventId } from "../editor"
import { showErrorToastFx } from "../toaster"
import {
	$events,
	$fieldUpdate,
	$isEventChanged,
	$isFormExpanded,
	$isShareButtonVisible,
	$isUpdate,
	$selectedEventsSortValue,
	$selectedVCardsSortValue,
	$vCards,
	deleteEventsFx,
	deleteVCardsFx,
	EventsScreenGate,
	fetchSortedEventsFx,
	fetchSortedVCardsFx,
	isEventIdChanged,
	isFieldUpdated,
	isFormExpandedChanged,
	isUpdateAvailable,
	SendCardFx,
	setSelectedEventsSortValue,
	setSelectedVCardsSortValue,
	shareButtonDisplay,
	statusCardFx,
	updateOnShare,
} from "./events-units"

$isFormExpanded.on(isFormExpandedChanged, (_, isExpanded) => isExpanded)

$isEventChanged.on(isEventIdChanged, (_, eventIdWasChanged) => eventIdWasChanged)
$isUpdate.on(isUpdateAvailable, (state) => !state).on(updateOnShare, (_, payload) => payload)
$isShareButtonVisible.on(shareButtonDisplay, (_, payload) => payload)

// eslint-disable-next-line effector/no-duplicate-on
$fieldUpdate.on(isFieldUpdated, (state) => !state).on(isFieldUpdated, (_, payload) => payload)
$selectedEventsSortValue.on(setSelectedEventsSortValue, (_, payload) => payload)
$selectedVCardsSortValue.on(setSelectedVCardsSortValue, (_, payload) => payload)

sample({
	clock: fetchSortedEventsFx.doneData,
	target: $events,
})

sample({
	clock: fetchSortedVCardsFx.doneData,
	target: $vCards,
})
sample({
	clock: EventsScreenGate.open,
	target: fetchSortedEventsFx,
})
SendCardFx.done.watch(() => {
	fetchSortedEventsFx()
})

sample({
	clock: [fetchSortedVCardsFx.failData, SendCardFx.failData, fetchSortedEventsFx.failData],
	target: showErrorToastFx,
})

deleteVCardsFx.done.watch(() => {
	fetchSortedVCardsFx($currentEventId.getState())
})
deleteEventsFx.done.watch(() => {
	fetchSortedEventsFx()
})
statusCardFx.done.watch(() => {
	fetchSortedVCardsFx($currentEventId.getState())
})
