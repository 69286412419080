import { AlignmentPosition } from "../domain/editor"
import classes from "./alignment-control.module.css"

type ControlProps = {
	value?: AlignmentPosition
	onChange?: (position: AlignmentPosition) => void
}

export function AlignmentControl(props: ControlProps) {
	const { value, onChange } = props

	return (
		<div className={classes.root}>
			<AlignmentButton position="1:1" value={value} onChange={onChange} />
			<AlignmentButton position="1:2" value={value} onChange={onChange} />
			<AlignmentButton position="1:3" value={value} onChange={onChange} />

			<AlignmentButton position="2:1" value={value} onChange={onChange} />
			<AlignmentButton position="2:2" value={value} onChange={onChange} />
			<AlignmentButton position="2:3" value={value} onChange={onChange} />

			<AlignmentButton position="3:1" value={value} onChange={onChange} />
			<AlignmentButton position="3:2" value={value} onChange={onChange} />
			<AlignmentButton position="3:3" value={value} onChange={onChange} />
		</div>
	)
}

type AlignmentButtonProps = {
	position: AlignmentPosition
	value?: AlignmentPosition
	onChange?: (position: AlignmentPosition) => void
}

function AlignmentButton(props: AlignmentButtonProps) {
	const { position, value, onChange } = props

	const icon = (() => {
		if (position !== value) {
			return <Empty />
		}

		const column = position.split(":")[1]

		switch (column) {
			case "1":
				return <Left />
			case "2":
				return <Center />
			case "3":
				return <Right />
			default:
				return <Empty />
		}
	})()

	return (
		<button type="button" className={classes.button} onClick={() => onChange?.(position)}>
			{icon}
		</button>
	)
}

function Left() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={40} height={39} fill="none">
			<path
				fill="var(--ion-color-primary)"
				fillRule="evenodd"
				d="M0 2a2 2 0 0 1 2-2h21a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Zm0 16a2 2 0 0 1 2-2h36a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-4Zm2 13a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H2Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}

function Right() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={40} height={39} fill="none">
			<path
				fill="var(--ion-color-primary)"
				fillRule="evenodd"
				d="M40 2a2 2 0 0 0-2-2H17a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h21a2 2 0 0 0 2-2V2Zm0 16a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2v-4Zm-2 13a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H25a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h13Z"
				clipRule="evenodd"
			/>
		</svg>
	)
}

function Center() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={40} height={39} fill="none">
			<path
				fill="var(--ion-color-primary)"
				d="M7 2a2 2 0 0 1 2-2h21a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H9a2 2 0 0 1-2-2V2ZM0 18a2 2 0 0 1 2-2h36a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-4Zm13 13a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h13a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2H13Z"
			/>
		</svg>
	)
}

function Empty() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={6} height={6} fill="none">
			<circle cx={3} cy={3} r={3} fill="#D9D9D9" />
		</svg>
	)
}
